<template>
  <v-container fluid>
    <!-- Loading indicator -->
    <div v-if="isLoading" class="d-flex justify-center my-4">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
      <span class="ml-2">Loading attorney data...</span>
    </div>

    <!-- Fees Table -->
    <v-card v-if="!isLoading || feesTableData.length > 0">
      <v-card-title>Fees by Month</v-card-title>
      <div style="overflow-x: auto; width: 100%;">
        <v-data-table
          :headers="feesHeaders"
          :items="feesTableData"
          :no-data-text="
            'No attorney fee data available for the selected period'
          "
          class="elevation-1 attorney-fees-table"
          :items-per-page="10"
          sort-by="attorney"
          :footer-props="{
            'items-per-page-options': [10, 25, 50, -1],
            'items-per-page-text': 'Rows per page:',
          }"
        >
          <!-- Attorney column with tooltip -->
          <template v-slot:item.attorney="{ item }">
            <td>
              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{ item.attorney }}</span>
                  </template>
                  <span>{{ getAttorneyFullName(item.attorney) }}</span>
                </v-tooltip>
              </div>
            </td>
          </template>

          <!-- Dynamic month columns with clickable fees -->
          <template
            v-for="header in feesHeaders.slice(2)"
            v-slot:[`item.${header.value}`]="{ item }"
          >
            <td
              :key="header.value"
              :class="
                getFeesClass(item[header.value], header.value, item.attorney)
              "
            >
              <div>
                <template v-if="item[header.value] > 0">
                  <a
                    @click="
                      showFeeDetails(
                        item.attorney,
                        header.value,
                        item[header.value]
                      )
                    "
                    class="clickable-cell"
                  >
                    <v-tooltip v-if="header.value === currentMonthKey" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">{{
                          formatCurrency(item[header.value])
                        }}</span>
                      </template>
                      <span>{{
                        getFeeTooltip(item[header.value], item.attorney)
                      }}</span>
                    </v-tooltip>
                    <span v-else>{{ formatCurrency(item[header.value]) }}</span>
                  </a>
                </template>
                <template v-else>
                  <span>-</span>
                </template>
              </div>
            </td>
          </template>

          <!-- Total column with clickable fee, showing monthly average -->
          <template v-slot:item.total="{ item }">
            <td :class="getFeesAverageClass(item.total, item.monthCount, item)">
              <div class="d-flex align-center">
                <a
                  @click="showFeeDetails(item.attorney, null, item.total)"
                  class="clickable-cell mr-2"
                >
                  {{ formatCurrency(item.total) }}
                </a>
                <div class="monthly-average">
                  ({{ formatCurrency(item.total / (item.monthCount || 1)) }}/mo)
                </div>
              </div>
            </td>
          </template>
        </v-data-table>
      </div>
    </v-card>

    <!-- Demands Table -->
    <v-card v-if="!isLoading || demandsTableData.length > 0" class="mt-4">
      <v-card-title>Demands by Month</v-card-title>
      <div style="overflow-x: auto; width: 100%;">
        <v-data-table
          :headers="demandsHeaders"
          :items="demandsTableData"
          :no-data-text="
            'No attorney demand data available for the selected period'
          "
          class="elevation-1 attorney-demands-table"
          :items-per-page="10"
          sort-by="attorney"
          :footer-props="{
            'items-per-page-options': [10, 25, 50, -1],
            'items-per-page-text': 'Rows per page:',
          }"
        >
          <!-- Attorney column with tooltip -->
          <template v-slot:item.attorney="{ item }">
            <td>
              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{ item.attorney }}</span>
                  </template>
                  <span>{{ getAttorneyFullName(item.attorney) }}</span>
                </v-tooltip>
              </div>
            </td>
          </template>

          <!-- Dynamic month columns with clickable demands -->
          <template
            v-for="header in demandsHeaders.slice(2)"
            v-slot:[`item.${header.value}`]="{ item }"
          >
            <td
              :key="header.value"
              :class="getDemandsClass(item[header.value], header.value)"
            >
              <div>
                <template v-if="item[header.value] > 0">
                  <a
                    @click="
                      showDemandDetails(
                        item.attorney,
                        header.value,
                        item[header.value]
                      )
                    "
                    class="clickable-cell"
                  >
                    <v-tooltip v-if="header.value === currentMonthKey" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">{{
                          item[header.value]
                        }}</span>
                      </template>
                      <span>{{ getDemandTooltip(item[header.value]) }}</span>
                    </v-tooltip>
                    <span v-else>{{ item[header.value] }}</span>
                  </a>
                </template>
                <template v-else>
                  <span>-</span>
                </template>
              </div>
            </td>
          </template>

          <!-- Total column with clickable demands -->
          <template v-slot:item.total="{ item }">
            <td :class="getDemandsAverageClass(item.total, item.monthCount)">
              <div class="d-flex align-center">
                <a
                  @click="showDemandDetails(item.attorney, null, item.total)"
                  class="clickable-cell mr-2"
                >
                  {{ item.total }}
                </a>
                <div class="monthly-average">
                  ({{ formatAverage(item.total, item.monthCount) }}/mo)
                </div>
              </div>
            </td>
          </template>
        </v-data-table>
      </div>
    </v-card>

    <!-- Settlements Table -->
    <v-card v-if="!isLoading || settlementsTableData.length > 0" class="mt-4">
      <v-card-title>Settlements by Month</v-card-title>
      <div style="overflow-x: auto; width: 100%;">
        <v-data-table
          :headers="settlementsHeaders"
          :items="settlementsTableData"
          :no-data-text="
            'No attorney settlement data available for the selected period'
          "
          class="elevation-1 attorney-settlement-table"
          :items-per-page="10"
          sort-by="attorney"
          :footer-props="{
            'items-per-page-options': [10, 25, 50, -1],
            'items-per-page-text': 'Rows per page:',
          }"
        >
          <!-- Attorney column with tooltip -->
          <template v-slot:item.attorney="{ item }">
            <td>
              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{ item.attorney }}</span>
                  </template>
                  <span>{{ getAttorneyFullName(item.attorney) }}</span>
                </v-tooltip>
              </div>
            </td>
          </template>

          <!-- Dynamic month columns with clickable settlements -->
          <template
            v-for="header in settlementsHeaders.slice(2)"
            v-slot:[`item.${header.value}`]="{ item }"
          >
            <td
              :key="header.value"
              :class="
                getSettlementsClass(
                  item[header.value],
                  header.value,
                  item.attorney
                )
              "
            >
              <div>
                <template v-if="item[header.value] > 0">
                  <a
                    @click="
                      showSettlementDetails(
                        item.attorney,
                        header.value,
                        item[header.value]
                      )
                    "
                    class="clickable-cell"
                  >
                    <v-tooltip v-if="header.value === currentMonthKey" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">{{
                          item[header.value]
                        }}</span>
                      </template>
                      <span>{{
                        getSettlementTooltip(item[header.value], item.attorney)
                      }}</span>
                    </v-tooltip>
                    <span v-else>{{ item[header.value] }}</span>
                  </a>
                </template>
                <template v-else>
                  <span>-</span>
                </template>
              </div>
            </td>
          </template>

          <!-- Total column with clickable settlements -->
          <template v-slot:item.total="{ item }">
            <td
              :class="
                getSettlementsAverageClass(item.total, item.monthCount, item)
              "
            >
              <div class="d-flex align-center">
                <a
                  @click="
                    showSettlementDetails(item.attorney, null, item.total)
                  "
                  class="clickable-cell mr-2"
                >
                  {{ item.total }}
                </a>
                <div class="monthly-average">
                  ({{ formatAverage(item.total, item.monthCount) }}/mo)
                </div>
              </div>
            </td>
          </template>
        </v-data-table>
      </div>
    </v-card>

    <!-- Fee Details Dialog -->
    <v-dialog v-model="feeDetailsDialog" max-width="900px" scrollable>
      <v-card v-if="selectedFeeDetails">
        <v-card-title class="headline">
          {{ getFeeTitlePrefix() }}
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="mx-1">{{
                getAttorneyFullName(selectedFeeDetails.attorney)
              }}</span>
            </template>
            <span v-if="isComboAttorney(selectedFeeDetails.attorney)">
              Includes fees from:
              {{ getIncludedAttorneysText(selectedFeeDetails.attorney) }}
            </span>
          </v-tooltip>
          {{ getFeeTitleSuffix() }}
          <v-spacer></v-spacer>
          <v-btn icon @click="feeDetailsDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-progress-circular
            v-if="selectedFeeDetails.loading"
            indeterminate
            color="primary"
            size="64"
            class="my-6 d-block mx-auto"
          ></v-progress-circular>

          <div
            v-else-if="selectedFeeDetails.error"
            class="text-center red--text"
          >
            {{ selectedFeeDetails.error }}
          </div>

          <div
            v-else-if="selectedFeeDetails.fees.length === 0"
            class="text-center"
          >
            No fee details found for this selection.
          </div>

          <template v-else>
            <v-card-subtitle class="pb-0 d-flex align-center">
              <div>{{ selectedFeeDetails.fees.length }} fees found</div>
              <v-spacer></v-spacer>
              <div class="subtitle-1 font-weight-bold">
                Total: {{ formatCurrency(calculateFeesTotal()) }}
              </div>
            </v-card-subtitle>

            <v-text-field
              v-model="feeDetailsSearch"
              label="Search fees"
              prepend-icon="mdi-magnify"
              clearable
              hide-details
              class="mb-4"
            ></v-text-field>

            <v-data-table
              :headers="feeDetailsHeaders"
              :items="selectedFeeDetails.fees"
              :items-per-page="10"
              sort-by="feeDate"
              sort-desc
              :search="feeDetailsSearch"
              :footer-props="{
                'items-per-page-options': [5, 10, 15, 20, -1],
                'items-per-page-text': 'Rows per page:',
              }"
              class="elevation-1 fee-details-table"
            >
              <template v-slot:item.fee="{ item }">
                <span class="fee-amount">{{ formatCurrency(item.fee) }}</span>
              </template>
              <template v-slot:item.feeDate="{ item }">
                {{ formatDate(item.feeDate) }}
              </template>
              <template v-slot:item.attorney="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{ item.attorney }}</span>
                  </template>
                  <span>{{ getAttorneyFullName(item.attorney) }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </template>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="success"
            text
            :disabled="
              !selectedFeeDetails ||
                !selectedFeeDetails.fees ||
                selectedFeeDetails.fees.length === 0
            "
            @click="exportFeesToCSV"
          >
            <v-icon left>mdi-file-export</v-icon>
            Export to CSV
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="feeDetailsDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Demand Details Dialog -->
    <v-dialog v-model="demandDetailsDialog" max-width="900px" scrollable>
      <v-card v-if="selectedDemandDetails">
        <v-card-title class="headline">
          {{ getDemandTitlePrefix() }}
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="mx-1">{{
                getAttorneyFullName(selectedDemandDetails.attorney)
              }}</span>
            </template>
            <span v-if="isComboAttorney(selectedDemandDetails.attorney)">
              Includes demands from:
              {{ getIncludedAttorneysText(selectedDemandDetails.attorney) }}
            </span>
          </v-tooltip>
          {{ getDemandTitleSuffix() }}
          <v-spacer></v-spacer>
          <v-btn icon @click="demandDetailsDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-progress-circular
            v-if="selectedDemandDetails.loading"
            indeterminate
            color="primary"
            size="64"
            class="my-6 d-block mx-auto"
          ></v-progress-circular>

          <div
            v-else-if="selectedDemandDetails.error"
            class="text-center red--text"
          >
            {{ selectedDemandDetails.error }}
          </div>

          <div
            v-else-if="selectedDemandDetails.demands.length === 0"
            class="text-center"
          >
            No demand details found for this selection.
          </div>

          <template v-else>
            <v-card-subtitle class="pb-0 d-flex align-center">
              <div>
                {{ selectedDemandDetails.demands.length }} demands found
              </div>
              <v-spacer></v-spacer>
            </v-card-subtitle>

            <v-text-field
              v-model="demandDetailsSearch"
              label="Search demands"
              prepend-icon="mdi-magnify"
              clearable
              hide-details
              class="mb-4"
            ></v-text-field>

            <v-data-table
              :headers="demandDetailsHeaders"
              :items="selectedDemandDetails.demands"
              :items-per-page="10"
              sort-by="demandDate"
              sort-desc
              :search="demandDetailsSearch"
              :footer-props="{
                'items-per-page-options': [5, 10, 15, 20, -1],
                'items-per-page-text': 'Rows per page:',
              }"
              class="elevation-1 demand-details-table"
            >
              <template v-slot:item.demandDate="{ item }">
                {{ formatDate(item.demandDate) }}
              </template>
              <template v-slot:item.attorney="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{ item.attorney }}</span>
                  </template>
                  <span>{{ getAttorneyFullName(item.attorney) }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </template>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="success"
            text
            :disabled="
              !selectedDemandDetails ||
                !selectedDemandDetails.demands ||
                selectedDemandDetails.demands.length === 0
            "
            @click="exportDemandsToCSV"
          >
            <v-icon left>mdi-file-export</v-icon>
            Export to CSV
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="demandDetailsDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Settlement Details Dialog -->
    <v-dialog v-model="settlementDetailsDialog" max-width="900px" scrollable>
      <v-card v-if="selectedSettlementDetails">
        <v-card-title class="headline">
          {{ getSettlementTitlePrefix() }}
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on" class="mx-1">{{
                getAttorneyFullName(selectedSettlementDetails.attorney)
              }}</span>
            </template>
            <span v-if="isComboAttorney(selectedSettlementDetails.attorney)">
              Includes settlements from:
              {{ getIncludedAttorneysText(selectedSettlementDetails.attorney) }}
            </span>
          </v-tooltip>
          {{ getSettlementTitleSuffix() }}
          <v-spacer></v-spacer>
          <v-btn icon @click="settlementDetailsDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-progress-circular
            v-if="selectedSettlementDetails.loading"
            indeterminate
            color="primary"
            size="64"
            class="my-6 d-block mx-auto"
          ></v-progress-circular>

          <div
            v-else-if="selectedSettlementDetails.error"
            class="text-center red--text"
          >
            {{ selectedSettlementDetails.error }}
          </div>

          <div
            v-else-if="selectedSettlementDetails.settlements.length === 0"
            class="text-center"
          >
            No settlement details found for this selection.
          </div>

          <template v-else>
            <v-card-subtitle class="pb-0 d-flex align-center">
              <div>
                {{ selectedSettlementDetails.settlements.length }} settlements
                found
              </div>
              <v-spacer></v-spacer>
            </v-card-subtitle>

            <v-text-field
              v-model="settlementDetailsSearch"
              label="Search settlements"
              prepend-icon="mdi-magnify"
              clearable
              hide-details
              class="mb-4"
            ></v-text-field>

            <v-data-table
              :headers="settlementDetailsHeaders"
              :items="selectedSettlementDetails.settlements"
              :items-per-page="10"
              sort-by="settlementDate"
              sort-desc
              :search="settlementDetailsSearch"
              :footer-props="{
                'items-per-page-options': [5, 10, 15, 20, -1],
                'items-per-page-text': 'Rows per page:',
              }"
              class="elevation-1 settlement-details-table"
            >
              <template v-slot:item.settlementDate="{ item }">
                {{ formatDate(item.settlementDate) }}
              </template>
              <template v-slot:item.attorney="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{ item.attorney }}</span>
                  </template>
                  <span>{{ getAttorneyFullName(item.attorney) }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </template>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="success"
            text
            :disabled="
              !selectedSettlementDetails ||
                !selectedSettlementDetails.settlements ||
                selectedSettlementDetails.settlements.length === 0
            "
            @click="exportSettlementsToCSV"
          >
            <v-icon left>mdi-file-export</v-icon>
            Export to CSV
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="settlementDetailsDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "OrgStaffAttorneyStats",
  data() {
    return {
      // Raw data from GraphQL
      feesRaw: [],
      demandsRaw: [],
      settlementsRaw: [],

      // Processed table data/headers
      feesHeaders: [],
      feesTableData: [],
      demandsHeaders: [],
      demandsTableData: [],
      settlementsHeaders: [],
      settlementsTableData: [],

      // Simple loading indicator
      loadingQueries: 0,
      totalQueries: 3,
      isRefreshing: false,

      // Store the imitated user's data
      imitatedUserData: null,

      // Store organization data
      organization: null,

      // Data for showing fee details
      selectedFeeDetails: null,
      feeDetailsDialog: false,
      feeDetailsHeaders: [
        { text: "Case ID", value: "caseId", width: "100px" },
        { text: "Case Number", value: "caseNumber", width: "180px" },
        { text: "Client Name", value: "clientName", width: "200px" },
        { text: "Fee", value: "fee", width: "120px", align: "right" },
        { text: "Fee Date", value: "feeDate", width: "120px" },
        { text: "Attorney", value: "attorney", width: "150px" },
      ],
      feeDetailsSearch: "",

      // Data for showing demand details
      selectedDemandDetails: null,
      demandDetailsDialog: false,
      demandDetailsHeaders: [
        { text: "Case Number", value: "caseNumber", width: "180px" },
        { text: "Client Name", value: "clientName", width: "200px" },
        { text: "Demand Date", value: "demandDate", width: "120px" },
        { text: "Attorney", value: "attorney", width: "150px" },
      ],
      demandDetailsSearch: "",

      // Data for showing settlement details
      selectedSettlementDetails: null,
      settlementDetailsDialog: false,
      settlementDetailsHeaders: [
        { text: "Case Number", value: "caseNumber", width: "180px" },
        { text: "Client Name", value: "clientName", width: "200px" },
        { text: "Settlement Date", value: "settlementDate", width: "120px" },
        { text: "Attorney", value: "attorney", width: "150px" },
      ],
      settlementDetailsSearch: "",

      // Loading state
      isLoading: true,

      // Monthly goals for attorney metrics
      monthlyGoals: {
        demands: 18, // 18 demands per month
        settlements: 15, // 15 settlements per month
        fees: 180000, // $180,000 in fees per month
      },

      // Litigation attorneys list - they have different goals
      litigationAttorneys: ["MCA", "JRT", "LCK", "LDM", "LP", "RCS"],

      // Monthly goals for litigation attorneys
      litigationGoals: {
        settlements: 6, // 6 settlements per month
        fees: 60000, // $60,000 in fees per month
      },
    };
  },
  computed: {
    ...mapGetters([
      "startDate",
      "endDate",
      "selectedTeam",
      "currentUser",
      "imitatedUser",
    ]),
    // Determine if the current user is an admin
    isAdmin() {
      if (!this.currentUser || !this.currentUser.roles) return false;
      return this.currentUser.roles.some((role) => role.name === "admin");
    },

    // Get the current user's team ID - if imitated user is set, use their team instead
    currentUserTeamId() {
      // If we have an imitated user and the imitated user data is loaded
      if (
        this.imitatedUser &&
        this.imitatedUserData &&
        this.imitatedUserData.teams &&
        this.imitatedUserData.teams.length > 0
      ) {
        return this.imitatedUserData.teams[0].id;
      }

      // Otherwise fall back to current user's team
      if (
        !this.currentUser ||
        !this.currentUser.teams ||
        this.currentUser.teams.length === 0
      ) {
        return null;
      }
      return this.currentUser.teams[0].id;
    },

    // Get the current month in YYYY-MM-DD format
    currentMonthKey() {
      const now = new Date();
      return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
        2,
        "0"
      )}-01`;
    },

    // Current day of month (for projecting end-of-month values)
    currentDayOfMonth() {
      return new Date().getDate();
    },

    // Days in current month
    daysInCurrentMonth() {
      const now = new Date();
      return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
    },
  },
  watch: {
    // Watch for changes to the selected team
    selectedTeam: {
      immediate: true,
      handler(newTeamId, oldTeamId) {
        // Only update if it's actually changed to prevent loops
        if (newTeamId !== oldTeamId) {
          this.refetchQueries();
        }
      },
    },

    // Watch for changes to imitatedUser and load the user's data
    imitatedUser: {
      immediate: true,
      handler(newImitatedUser, oldImitatedUser) {
        // Only process if it actually changed
        if (newImitatedUser !== oldImitatedUser) {
          this.clearComponentData();

          if (newImitatedUser) {
            this.fetchImitatedUserData();
          } else {
            this.imitatedUserData = null;
            // If imitated user is cleared, reset to current user's team
            if (this.currentUserTeamId) {
              // Use nextTick to avoid immediate loop
              this.$nextTick(() => {
                this.setSelectedTeam(this.currentUserTeamId);
              });
            }
          }
        }
      },
    },

    // Watch for changes to the currentUser
    currentUser: {
      immediate: true,
      deep: true,
      handler(newUser, oldUser) {
        // Need to ensure we're not responding to the same user reference
        const newId = newUser ? newUser.id : null;
        const oldId = oldUser ? oldUser.id : null;

        if (newId !== oldId && newUser) {
          this.clearComponentData();

          if (newUser.teams && newUser.teams.length > 0) {
            // Using nextTick to avoid immediate reactions
            this.$nextTick(() => {
              this.setSelectedTeam(newUser.teams[0].id);
            });
          }
        }
      },
    },
  },
  apollo: {
    // Fees Query for Attorneys
    feesInPeriodByAttorneyByMonth: {
      query: gql`
        query feesInPeriodByAttorneyByMonth(
          $startDate: String!
          $endDate: String!
        ) {
          feesInPeriodByAttorneyByMonth(
            startDate: $startDate
            endDate: $endDate
          ) {
            attorney
            monthStart
            total
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
      fetchPolicy: "network-only", // Always fetch fresh data
      result({ data }) {
        if (data && data.feesInPeriodByAttorneyByMonth) {
          this.feesRaw = data.feesInPeriodByAttorneyByMonth;
          this.buildFeesTable();
        }
        this.markQueryComplete();
      },
    },
    // Demands Query for Attorneys
    demandsInPeriodByAttorneyByMonth: {
      query: gql`
        query demandsInPeriodByAttorneyByMonth(
          $startDate: String!
          $endDate: String!
        ) {
          demandsInPeriodByAttorneyByMonth(
            startDate: $startDate
            endDate: $endDate
          ) {
            attorney
            monthStart
            total
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
      fetchPolicy: "network-only", // Always fetch fresh data
      result({ data }) {
        if (data && data.demandsInPeriodByAttorneyByMonth) {
          this.demandsRaw = data.demandsInPeriodByAttorneyByMonth;
          this.buildDemandsTable();
        }
        this.markQueryComplete();
      },
    },
    // Settlements Query for Attorneys
    settlementsInPeriodByAttorneyByMonth: {
      query: gql`
        query settlementsInPeriodByAttorneyByMonth(
          $startDate: String!
          $endDate: String!
        ) {
          settlementsInPeriodByAttorneyByMonth(
            startDate: $startDate
            endDate: $endDate
          ) {
            attorney
            monthStart
            total
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
      fetchPolicy: "network-only", // Always fetch fresh data
      result({ data }) {
        if (data && data.settlementsInPeriodByAttorneyByMonth) {
          this.settlementsRaw = data.settlementsInPeriodByAttorneyByMonth;
          this.buildSettlementsTable();
        }
        this.markQueryComplete();
      },
    },
    // Query to get organization teams and users for team selection
    organization: {
      query: gql`
        query($id: ID!) {
          organization(id: $id) {
            id
            name
            teams {
              id
              name
              active
              users {
                id
                firstName
                lastName
                staffCode
                roles {
                  name
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          id: this.currentUser ? this.currentUser.organization.id : null,
        };
      },
      fetchPolicy: "network-only", // Always fetch fresh data
      skip() {
        return !this.currentUser || !this.currentUser.organization;
      },
      result({ data }) {
        if (data && data.organization) {
          // Store the organization data for reference
          this.organization = data.organization;

          // Filter to only active teams
          this.teamOptions = data.organization.teams
            .filter((team) => team.active)
            .map((team) => ({
              id: team.id,
              name: team.name,
            }));

          // If we don't have a selected team yet but have a current user team, set it
          if (!this.selectedTeam && this.currentUserTeamId) {
            this.setSelectedTeam(this.currentUserTeamId);
          }
        }
      },
    },
  },
  methods: {
    ...mapActions(["setSelectedTeam"]),

    // Get all attorneys to include in fee details
    getAttorneysToInclude(mainAttorney) {
      // Define attorney combinations
      const attorneyCombinations = {
        LS: ["LS", "EMR", "KNP"],
        CLP: ["CLP", "PPR"],
        ACS: ["ACS", "TRS", "RAS"],
        RCS: ["RCS", "WC", "SSD", "PL"],
      };

      return attorneyCombinations[mainAttorney] || [mainAttorney];
    },

    // Show fee details
    showFeeDetails(attorney, monthStart, total) {
      if (!total || total <= 0) return; // Don't do anything if there are no fees

      // Show loading dialog
      this.selectedFeeDetails = {
        loading: true,
        attorney,
        monthStart,
        total,
        fees: [],
      };
      this.feeDetailsDialog = true;

      // Query the individual fees
      this.$apollo
        .query({
          query: gql`
            query individualFeesInPeriodByAttorneyByMonth(
              $startDate: String!
              $endDate: String!
            ) {
              individualFeesInPeriodByAttorneyByMonth(
                startDate: $startDate
                endDate: $endDate
              ) {
                caseId
                caseNumber
                clientName
                fee
                feeDate
                attorney
              }
            }
          `,
          variables: {
            startDate: this.createMonthStartDate(monthStart),
            endDate: this.createMonthEndDate(monthStart),
          },
          fetchPolicy: "network-only",
        })
        .then(({ data }) => {
          if (data && data.individualFeesInPeriodByAttorneyByMonth) {
            // Get the list of attorneys to include in this query
            const attorneysToInclude = this.getAttorneysToInclude(attorney);

            // Filter the results by the list of attorneys and exclude CS
            const allFees = data.individualFeesInPeriodByAttorneyByMonth;
            const filteredFees = allFees.filter(
              (fee) =>
                attorneysToInclude.includes(fee.attorney) &&
                fee.attorney !== "CS" &&
                fee.attorney !== "WDM"
            );

            this.selectedFeeDetails.fees = filteredFees;
            this.selectedFeeDetails.loading = false;
          }
        })
        .catch((error) => {
          console.error("Error loading fee details:", error);
          this.selectedFeeDetails.loading = false;
          this.selectedFeeDetails.error = "Failed to load fee details";
        });
    },

    // Create a date object for the start of a month (format: YYYY-MM-DD)
    createMonthStartDate(monthStart) {
      if (!monthStart) {
        // If no month specified, use the current date range
        return this.startDate;
      }
      return monthStart; // Already in YYYY-MM-DD format for the first day of the month
    },

    // Create a date object for the end of a month (format: YYYY-MM-DD)
    createMonthEndDate(monthStart) {
      if (!monthStart) {
        // If no month specified, use the current date range
        return this.endDate;
      }

      // Parse the month string
      const [year, month] = monthStart.split("-").map(Number);

      // Create a date for the last day of the month
      // Set to first day of next month, then subtract 1 day
      const lastDay = new Date(year, month, 0);

      // Format as YYYY-MM-DD
      return `${lastDay.getFullYear()}-${String(
        lastDay.getMonth() + 1
      ).padStart(2, "0")}-${String(lastDay.getDate()).padStart(2, "0")}`;
    },

    // Clear all component data
    clearComponentData() {
      this.feesRaw = [];
      this.feesHeaders = [];
      this.feesTableData = [];
      this.demandsRaw = [];
      this.demandsHeaders = [];
      this.demandsTableData = [];
      this.settlementsRaw = [];
      this.settlementsHeaders = [];
      this.settlementsTableData = [];
      this.loadingQueries = 0;
    },

    markQueryComplete() {
      this.loadingQueries++;
      if (this.loadingQueries >= this.totalQueries) {
        this.isLoading = false;
      }
    },

    // Helper method to refetch all queries
    refetchQueries() {
      this.isLoading = true;
      this.loadingQueries = 0;
      if (this.$apollo.queries.feesInPeriodByAttorneyByMonth) {
        this.$apollo.queries.feesInPeriodByAttorneyByMonth.refetch();
      }
      if (this.$apollo.queries.demandsInPeriodByAttorneyByMonth) {
        this.$apollo.queries.demandsInPeriodByAttorneyByMonth.refetch();
      }
      if (this.$apollo.queries.settlementsInPeriodByAttorneyByMonth) {
        this.$apollo.queries.settlementsInPeriodByAttorneyByMonth.refetch();
      }
    },

    // Format a number to one decimal place
    formatAverage(total, count) {
      if (!count) return "0.0";
      return (total / count).toFixed(1);
    },

    // Format currency values for the fees table
    formatCurrency(value) {
      if (value === undefined || value === null) return "";

      // Convert to number if it's a string
      const numValue = typeof value === "string" ? parseFloat(value) : value;

      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(numValue);
    },

    // Format a date for display
    formatDate(dateString) {
      if (!dateString) return "";

      const date = new Date(dateString);
      return new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      }).format(date);
    },

    // Method for fees conditional formatting
    getFeesClass(value, monthKey, attorney) {
      if (value === undefined || value === null) return "";

      // If attorney parameter is provided, use it
      const attorneyCode = attorney || null;

      // Determine if this is a litigation attorney
      const isLitigation = this.isLitigationAttorney(attorneyCode);

      // Set goal based on attorney type
      const goalValue = isLitigation
        ? this.litigationGoals.fees
        : this.monthlyGoals.fees;
      const mediumThreshold = isLitigation ? 50000 : 150000;

      // For current month, use the projected end-of-month value
      if (monthKey === this.currentMonthKey) {
        const numValue = typeof value === "string" ? parseFloat(value) : value;
        const projectedValue = this.projectMonthEndValue(numValue);

        // Compare projected value to goal
        if (projectedValue >= goalValue)
          return "high-value projected-high-value";
        if (projectedValue >= mediumThreshold && projectedValue < goalValue)
          return "medium-value projected-medium-value";
        return "low-value projected-low-value";
      }

      // For past months, use the actual value
      const numValue = typeof value === "string" ? parseFloat(value) : value;

      if (numValue >= goalValue) return "high-value";
      if (numValue >= mediumThreshold && numValue < goalValue)
        return "medium-value";
      return "low-value";
    },

    // Method for fees average conditional formatting
    getFeesAverageClass(total, count, item) {
      if (total === undefined || total === null || !count) return "";

      // If item parameter is provided, get attorney from it
      const attorney = item ? item.attorney : null;

      // Determine if this is a litigation attorney
      const isLitigation = this.isLitigationAttorney(attorney);

      // Set goal based on attorney type
      const goalValue = isLitigation
        ? this.litigationGoals.fees
        : this.monthlyGoals.fees;
      const mediumThreshold = isLitigation ? 50000 : 150000;

      // Convert to number if it's a string
      const numValue = typeof total === "string" ? parseFloat(total) : total;
      const average = numValue / count;

      if (average >= goalValue) return "high-value";
      if (average >= mediumThreshold && average < goalValue)
        return "medium-value";
      return "low-value";
    },

    // Helper function to check if an attorney is a litigation attorney
    isLitigationAttorney(attorneyCode) {
      if (!attorneyCode) return false;
      return this.litigationAttorneys.includes(attorneyCode);
    },

    // Helper to find the attorney for a specific cell
    findParentAttorney() {
      // This is a placeholder - in a real implementation we would need
      // to tie this to the specific row being rendered
      // For now, we'll return null and handle default case
      return null;
    },

    // Method for settlements conditional formatting
    getSettlementsClass(value, monthKey, attorney) {
      if (value === undefined || value === null) return "";

      // If attorney parameter is provided, use it
      const attorneyCode = attorney || null;

      // Determine if this is a litigation attorney
      const isLitigation = this.isLitigationAttorney(attorneyCode);

      // Set goal based on attorney type
      const goalValue = isLitigation
        ? this.litigationGoals.settlements
        : this.monthlyGoals.settlements;
      const mediumThreshold = isLitigation ? 4 : 12;

      // For current month, use the projected end-of-month value
      if (monthKey === this.currentMonthKey) {
        const numValue = typeof value === "string" ? parseFloat(value) : value;
        const projectedValue = this.projectMonthEndValue(numValue);

        // Compare projected value to goal
        if (projectedValue >= goalValue)
          return "high-value-settlement projected-high-value";
        if (projectedValue >= mediumThreshold && projectedValue < goalValue)
          return "medium-value-settlement projected-medium-value";
        return "low-value-settlement projected-low-value";
      }

      // For past months, use the actual value
      const numValue = typeof value === "string" ? parseFloat(value) : value;

      if (numValue >= goalValue) return "high-value-settlement";
      if (numValue >= mediumThreshold && numValue <= goalValue)
        return "medium-value-settlement";
      return "low-value-settlement";
    },

    // Method for settlements average conditional formatting
    getSettlementsAverageClass(total, count, item) {
      if (total === undefined || total === null || !count) return "";

      // If item parameter is provided, get attorney from it
      const attorney = item ? item.attorney : null;

      // Determine if this is a litigation attorney
      const isLitigation = this.isLitigationAttorney(attorney);

      // Set goal based on attorney type
      const goalValue = isLitigation
        ? this.litigationGoals.settlements
        : this.monthlyGoals.settlements;
      const mediumThreshold = isLitigation ? 4 : 12;

      // Convert to number if it's a string
      const numValue = typeof total === "string" ? parseFloat(total) : total;
      const average = numValue / count;

      if (average >= goalValue) return "high-value-settlement";
      if (average >= mediumThreshold && average <= goalValue)
        return "medium-value-settlement";
      return "low-value-settlement";
    },

    // Get a tooltip message for settlements showing goal achievement percentage
    getSettlementTooltip(currentValue, attorney) {
      if (!currentValue) return "";

      const numValue =
        typeof currentValue === "string"
          ? parseFloat(currentValue)
          : currentValue;

      // Determine if this is a litigation attorney
      const isLitigation = this.isLitigationAttorney(attorney);
      const goalValue = isLitigation
        ? this.litigationGoals.settlements
        : this.monthlyGoals.settlements;

      // Calculate percentage of goal achieved
      const percentComplete = (numValue / goalValue) * 100;

      return `Current: ${numValue}, Goal: ${goalValue} (${Math.round(
        percentComplete
      )}% complete)`;
    },

    // Get a tooltip message for demands showing goal achievement percentage
    getDemandTooltip(currentValue) {
      if (!currentValue) return "";

      const numValue =
        typeof currentValue === "string"
          ? parseFloat(currentValue)
          : currentValue;

      // Litigation attorneys should not appear in the demands table
      const goalValue = this.monthlyGoals.demands;

      // Calculate percentage of goal achieved
      const percentComplete = (numValue / goalValue) * 100;

      return `Current: ${numValue}, Goal: ${goalValue} (${Math.round(
        percentComplete
      )}% complete)`;
    },

    // Get a tooltip message for fees showing goal achievement percentage
    getFeeTooltip(currentValue, attorney) {
      if (!currentValue) return "";

      const numValue =
        typeof currentValue === "string"
          ? parseFloat(currentValue)
          : currentValue;

      // Determine if this is a litigation attorney
      const isLitigation = this.isLitigationAttorney(attorney);
      const goalValue = isLitigation
        ? this.litigationGoals.fees
        : this.monthlyGoals.fees;

      // Calculate percentage of goal achieved
      const percentComplete = (numValue / goalValue) * 100;

      return `Current: ${this.formatCurrency(
        numValue
      )}, Goal: ${this.formatCurrency(goalValue)} (${Math.round(
        percentComplete
      )}% complete)`;
    },

    // Helper function to get attorney code from event
    getAttorneyFromCell(event) {
      // Try to get the attorney from the current row
      if (event && event.target) {
        const row = event.target.closest("tr");
        if (row) {
          // Try to find the first cell which usually contains the attorney code
          const firstCell = row.querySelector("td:first-child");
          if (firstCell) {
            return firstCell.textContent.trim();
          }
        }
      }
      return null;
    },

    // Helper to build a table with attorney as the first column
    // and each unique month as subsequent columns
    buildMonthlyTable(rawData) {
      // Ensure we have data to process
      if (!rawData || !Array.isArray(rawData) || rawData.length === 0) {
        return { headers: [], items: [] };
      }

      // Filter out CS and WDM attorneys
      const filteredData = rawData.filter(
        (item) => item.attorney !== "CS" && item.attorney !== "WDM"
      );

      // Define attorney mappings for combined stats
      const attorneyMappings = {
        EMR: "LS", // EMR fees should be included in LS stats
        KNP: "LS", // KNP fees should be included in LS stats
        PPR: "CLP", // PPR fees should be included in CLP stats
        TRS: "ACS", // TRS fees should be included in ACS stats
        RAS: "ACS", // RAS fees should be included in ACS stats
        WC: "RCS", // WC fees should be included in RCS stats
        SSD: "RCS", // SSD fees should be included in RCS stats
        PL: "RCS", // PL fees should be included in RCS stats
      };

      // 1) Collect all unique months
      const monthSet = new Set();
      filteredData.forEach((item) => {
        if (item && item.monthStart) {
          monthSet.add(item.monthStart);
        }
      });
      // Sort the months ascending; adjust if you prefer descending
      const months = Array.from(monthSet).sort();

      // 2) Build a map of attorney -> { attorney, [monthStart]: total }
      const attorneyMap = {};
      filteredData.forEach(({ attorney, monthStart, total }) => {
        if (!attorney) return; // Skip entries without attorney

        // Check if this attorney's fees should be mapped to another attorney
        const targetAttorney = attorneyMappings[attorney] || attorney;

        if (!attorneyMap[targetAttorney]) {
          attorneyMap[targetAttorney] = {
            attorney: targetAttorney,
            total: 0,
            monthCount: 0,
            // Store the original attorneys that make up this combined record
            includedAttorneys: targetAttorney === attorney ? [] : [attorney],
          };
        } else if (
          targetAttorney !== attorney &&
          !attorneyMap[targetAttorney].includedAttorneys.includes(attorney)
        ) {
          // Add to the list of included attorneys if not already there
          attorneyMap[targetAttorney].includedAttorneys.push(attorney);
        }

        // Only process if we have a valid monthStart
        if (monthStart) {
          const numericTotal =
            typeof total === "string" ? parseFloat(total) : total;

          // Store the month data (add to existing value if any)
          attorneyMap[targetAttorney][monthStart] =
            (attorneyMap[targetAttorney][monthStart] || 0) + numericTotal;

          // Update the running total
          attorneyMap[targetAttorney].total += numericTotal;

          // Only increment month count if this is the first time we're seeing this month for this attorney
          if (!attorneyMap[targetAttorney][`${monthStart}_counted`]) {
            attorneyMap[targetAttorney].monthCount += 1;
            attorneyMap[targetAttorney][`${monthStart}_counted`] = true;
          }
        }
      });

      // Clean up the temporary counting properties
      Object.values(attorneyMap).forEach((attorney) => {
        months.forEach((month) => {
          delete attorney[`${month}_counted`];
        });
      });

      // 3) Convert map to array of row objects
      const items = Object.values(attorneyMap);
      // Optional: sort attorneys alphabetically
      items.sort((a, b) => a.attorney.localeCompare(b.attorney));

      // 4) Build headers array
      const headers = [
        {
          text: "Attorney",
          value: "attorney",
          fixed: true,
          width: "120px",
          sortable: true,
        },
        {
          text: "Total",
          value: "total",
          fixed: true,
          width: "180px",
          sortable: true,
        },
      ];

      months.forEach((m) => {
        // Format date from "YYYY-MM-DD" to "MMM YY"
        // Parse the date parts manually to avoid timezone issues
        if (!m) return; // Skip if month is null

        const parts = m.split("-");
        if (parts.length < 2) return; // Skip if format is invalid

        const [year, month] = parts.map(Number);

        // Create date using the correct month (JS months are 0-based, so no adjustment needed when parsing directly)
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const monthName = monthNames[month - 1]; // Adjust for 1-based month in the string
        const yearSuffix = year.toString().substr(2, 2);
        const formattedDate = `${monthName} ${yearSuffix}`;

        headers.push({
          text: formattedDate,
          value: m, // keep the original date as the value for data binding
          width: "80px",
          sortable: true,
        });
      });

      return { headers, items };
    },

    buildFeesTable() {
      if (
        !this.feesRaw ||
        !Array.isArray(this.feesRaw) ||
        this.feesRaw.length === 0
      ) {
        // Reset the table data when there's no data
        this.feesHeaders = [
          { text: "Attorney", value: "attorney", fixed: true, width: "120px" },
          { text: "Total", value: "total", fixed: true, width: "180px" },
        ];
        this.feesTableData = [];
        return;
      }

      const { headers, items } = this.buildMonthlyTable(this.feesRaw);

      if (headers && headers.length > 0) {
        this.feesHeaders = headers;
      } else {
        this.feesHeaders = [
          { text: "Attorney", value: "attorney", fixed: true, width: "120px" },
          { text: "Total", value: "total", fixed: true, width: "180px" },
        ];
      }

      this.feesTableData = items;
    },

    // Fetch imitated user data to get their teams
    fetchImitatedUserData() {
      if (!this.imitatedUser) return;

      this.$apollo
        .query({
          query: gql`
            query($id: ID!) {
              user(id: $id) {
                id
                firstName
                lastName
                email
                teams {
                  id
                  name
                }
              }
            }
          `,
          variables: {
            id: this.imitatedUser,
          },
          fetchPolicy: "network-only", // Always fetch fresh data
        })
        .then(({ data }) => {
          if (data && data.user) {
            this.imitatedUserData = data.user;

            // If we now have imitated user data, update the team selection in Vuex
            if (
              this.imitatedUserData.teams &&
              this.imitatedUserData.teams.length > 0
            ) {
              const imitatedUserTeamId = this.imitatedUserData.teams[0].id;
              this.setSelectedTeam(imitatedUserTeamId);
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching imitated user data:", error);
        });
    },

    // Reset Apollo cache
    resetApolloCache() {
      try {
        if (this.$apollo.provider.defaultClient) {
          this.$apollo.provider.defaultClient.clearStore();
          this.$apollo.provider.defaultClient.cache.reset();
        }
      } catch (e) {
        console.error("Error resetting Apollo cache:", e);
      }
    },

    // Get attorney's full name by staff code
    getAttorneyFullName(staffCode) {
      if (!this.organization || !this.organization.teams) return staffCode;

      // Look through all teams to find the attorney
      let fullName = staffCode; // Default to staff code if not found

      this.organization.teams.forEach((team) => {
        if (team.users) {
          const user = team.users.find((user) => user.staffCode === staffCode);
          if (user) {
            fullName = `${user.firstName} ${user.lastName}`;
          }
        }
      });

      return fullName;
    },

    // Helper methods for fee details dialog title
    getFeeTitlePrefix() {
      if (!this.selectedFeeDetails) return "";
      const { total } = this.selectedFeeDetails;
      return `${this.formatCurrency(total)} in Fees by `;
    },

    getFeeTitleSuffix() {
      if (!this.selectedFeeDetails) return "";
      const { monthStart } = this.selectedFeeDetails;

      if (monthStart) {
        // Format the month for display
        const [year, month] = monthStart.split("-").map(Number);
        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        const formattedMonth = `${monthNames[month - 1]} ${year}`;
        return ` in ${formattedMonth}`;
      } else {
        return "";
      }
    },

    // Export fees to CSV
    exportFeesToCSV() {
      if (
        !this.selectedFeeDetails ||
        !this.selectedFeeDetails.fees ||
        this.selectedFeeDetails.fees.length === 0
      ) {
        return;
      }

      const fees = this.selectedFeeDetails.fees;
      const fileName = `attorney_fees_${
        this.selectedFeeDetails.attorney
      }_${new Date().toISOString().slice(0, 10)}.csv`;

      // Define the fields to include in the CSV
      const fields = [
        { key: "caseId", label: "Case ID" },
        { key: "caseNumber", label: "Case Number" },
        { key: "clientName", label: "Client Name" },
        { key: "fee", label: "Fee", formatter: this.formatCurrency },
        { key: "feeDate", label: "Fee Date", formatter: this.formatDate },
        { key: "attorney", label: "Attorney" },
      ];

      // Create CSV header row
      let csvContent =
        fields.map((field) => `"${field.label}"`).join(",") + "\n";

      // Add data rows
      fees.forEach((fee) => {
        const row = fields.map((field) => {
          // Get the value, apply formatter if specified
          let value = field.formatter
            ? field.formatter(fee[field.key])
            : fee[field.key];
          // Escape quotes and wrap in quotes
          return `"${String(value || "").replace(/"/g, '""')}"`;
        });
        csvContent += row.join(",") + "\n";
      });

      // Create a download link and trigger it
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");

      // Create a URL for the blob
      const url = URL.createObjectURL(blob);

      // Set link properties
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";

      // Add to document, click it, then remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    // Calculate the total fees
    calculateFeesTotal() {
      if (!this.selectedFeeDetails || !this.selectedFeeDetails.fees) return 0;
      return this.selectedFeeDetails.fees.reduce(
        (total, fee) => total + fee.fee,
        0
      );
    },

    // Check if the attorney is a combination of other attorneys
    isComboAttorney(attorney) {
      const attorneyCombinations = {
        LS: ["LS", "EMR", "KNP"],
        CLP: ["CLP", "PPR"],
        ACS: ["ACS", "TRS", "RAS"],
        RCS: ["RCS", "WC", "SSD", "PL"],
      };
      return (
        attorneyCombinations[attorney] &&
        attorneyCombinations[attorney].length > 1
      );
    },

    // Get the included attorneys text
    getIncludedAttorneysText(attorney) {
      const attorneyCombinations = {
        LS: ["LS", "EMR", "KNP"],
        CLP: ["CLP", "PPR"],
        ACS: ["ACS", "TRS", "RAS"],
        RCS: ["RCS", "WC", "SSD", "PL"],
      };
      if (this.isComboAttorney(attorney)) {
        return attorneyCombinations[attorney].join(", ");
      }
      return attorney;
    },

    // Show demand details
    showDemandDetails(attorney, monthStart, total) {
      if (!total || total <= 0) return; // Don't do anything if there are no demands

      // Show loading dialog
      this.selectedDemandDetails = {
        loading: true,
        attorney,
        monthStart,
        total,
        demands: [],
      };
      this.demandDetailsDialog = true;

      // Query the individual demands
      this.$apollo
        .query({
          query: gql`
            query individualDemandsInPeriodByAttorneyByMonth(
              $startDate: String!
              $endDate: String!
            ) {
              individualDemandsInPeriodByAttorneyByMonth(
                startDate: $startDate
                endDate: $endDate
              ) {
                caseNumber
                clientName
                demandDate
                attorney
              }
            }
          `,
          variables: {
            startDate: this.createMonthStartDate(monthStart),
            endDate: this.createMonthEndDate(monthStart),
          },
          fetchPolicy: "network-only",
        })
        .then(({ data }) => {
          if (data && data.individualDemandsInPeriodByAttorneyByMonth) {
            // Get the list of attorneys to include in this query
            const attorneysToInclude = this.getAttorneysToInclude(attorney);

            // Filter the results by the list of attorneys and exclude CS
            const allDemands = data.individualDemandsInPeriodByAttorneyByMonth;
            const filteredDemands = allDemands.filter(
              (demand) =>
                attorneysToInclude.includes(demand.attorney) &&
                demand.attorney !== "CS" &&
                demand.attorney !== "WDM"
            );

            this.selectedDemandDetails.demands = filteredDemands;
            this.selectedDemandDetails.loading = false;
          }
        })
        .catch((error) => {
          console.error("Error loading demand details:", error);
          this.selectedDemandDetails.loading = false;
          this.selectedDemandDetails.error = "Failed to load demand details";
        });
    },

    // Helper methods for demand details dialog title
    getDemandTitlePrefix() {
      if (!this.selectedDemandDetails) return "";
      const { total } = this.selectedDemandDetails;
      return `${total} Demands by `;
    },

    getDemandTitleSuffix() {
      if (!this.selectedDemandDetails) return "";
      const { monthStart } = this.selectedDemandDetails;

      if (monthStart) {
        // Format the month for display
        const [year, month] = monthStart.split("-").map(Number);
        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        const formattedMonth = `${monthNames[month - 1]} ${year}`;
        return ` in ${formattedMonth}`;
      } else {
        return "";
      }
    },

    buildDemandsTable() {
      if (
        !this.demandsRaw ||
        !Array.isArray(this.demandsRaw) ||
        this.demandsRaw.length === 0
      ) {
        // Reset the table data when there's no data
        this.demandsHeaders = [
          { text: "Attorney", value: "attorney", fixed: true, width: "120px" },
          { text: "Total", value: "total", fixed: true, width: "120px" },
        ];
        this.demandsTableData = [];
        return;
      }

      // Filter out litigation attorneys from demands table
      const filteredRaw = this.demandsRaw.filter(
        (item) => !this.isLitigationAttorney(item.attorney)
      );

      const { headers, items } = this.buildMonthlyTable(filteredRaw);

      if (headers && headers.length > 0) {
        // Replace the Total header to ensure consistent width
        const totalHeader = headers.find((h) => h.value === "total");
        if (totalHeader) {
          totalHeader.width = "120px";
        }
        this.demandsHeaders = headers;
      } else {
        this.demandsHeaders = [
          { text: "Attorney", value: "attorney", fixed: true, width: "120px" },
          { text: "Total", value: "total", fixed: true, width: "120px" },
        ];
      }

      this.demandsTableData = items;
    },

    // Export demands to CSV
    exportDemandsToCSV() {
      if (
        !this.selectedDemandDetails ||
        !this.selectedDemandDetails.demands ||
        this.selectedDemandDetails.demands.length === 0
      ) {
        return;
      }

      const demands = this.selectedDemandDetails.demands;
      const fileName = `attorney_demands_${
        this.selectedDemandDetails.attorney
      }_${new Date().toISOString().slice(0, 10)}.csv`;

      // Define the fields to include in the CSV
      const fields = [
        { key: "caseNumber", label: "Case Number" },
        { key: "clientName", label: "Client Name" },
        { key: "demandDate", label: "Demand Date", formatter: this.formatDate },
        { key: "attorney", label: "Attorney" },
      ];

      // Create CSV header row
      let csvContent =
        fields.map((field) => `"${field.label}"`).join(",") + "\n";

      // Add data rows
      demands.forEach((demand) => {
        const row = fields.map((field) => {
          // Get the value, apply formatter if specified
          let value = field.formatter
            ? field.formatter(demand[field.key])
            : demand[field.key];
          // Escape quotes and wrap in quotes
          return `"${String(value || "").replace(/"/g, '""')}"`;
        });
        csvContent += row.join(",") + "\n";
      });

      // Create a download link and trigger it
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");

      // Create a URL for the blob
      const url = URL.createObjectURL(blob);

      // Set link properties
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";

      // Add to document, click it, then remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    // Method for demands conditional formatting
    getDemandsClass(value, monthKey) {
      if (value === undefined || value === null) return "";

      // For current month, use the projected end-of-month value
      if (monthKey === this.currentMonthKey) {
        const numValue = typeof value === "string" ? parseFloat(value) : value;
        const projectedValue = this.projectMonthEndValue(numValue);

        // Compare projected value to goal
        if (projectedValue >= this.monthlyGoals.demands)
          return "high-value-demand projected-high-value";
        if (
          projectedValue >= 0.8 * this.monthlyGoals.demands &&
          projectedValue < this.monthlyGoals.demands
        )
          return "medium-value-demand projected-medium-value";
        return "low-value-demand projected-low-value";
      }

      // For past months, use the actual value
      const numValue = typeof value === "string" ? parseFloat(value) : value;

      if (numValue > 17) return "high-value-demand";
      if (numValue >= 15 && numValue <= 17) return "medium-value-demand";
      return "low-value-demand";
    },

    // Method for demands average conditional formatting
    getDemandsAverageClass(total, count) {
      if (total === undefined || total === null || !count) return "";

      // Convert to number if it's a string
      const numValue = typeof total === "string" ? parseFloat(total) : total;
      const average = numValue / count;

      if (average > 17) return "high-value-demand";
      if (average >= 12 && average <= 17) return "medium-value-demand";
      return "low-value-demand";
    },

    buildSettlementsTable() {
      if (
        !this.settlementsRaw ||
        !Array.isArray(this.settlementsRaw) ||
        this.settlementsRaw.length === 0
      ) {
        // Reset the table data when there's no data
        this.settlementsHeaders = [
          { text: "Attorney", value: "attorney", fixed: true, width: "120px" },
          { text: "Total", value: "total", fixed: true, width: "120px" },
        ];
        this.settlementsTableData = [];
        return;
      }

      const { headers, items } = this.buildMonthlyTable(this.settlementsRaw);

      if (headers && headers.length > 0) {
        // Replace the Total header to ensure consistent width
        const totalHeader = headers.find((h) => h.value === "total");
        if (totalHeader) {
          totalHeader.width = "120px";
        }
        this.settlementsHeaders = headers;
      } else {
        this.settlementsHeaders = [
          { text: "Attorney", value: "attorney", fixed: true, width: "120px" },
          { text: "Total", value: "total", fixed: true, width: "120px" },
        ];
      }

      this.settlementsTableData = items;
    },

    // Show settlement details
    showSettlementDetails(attorney, monthStart, total) {
      if (!total || total <= 0) return; // Don't do anything if there are no settlements

      // Show loading dialog
      this.selectedSettlementDetails = {
        loading: true,
        attorney,
        monthStart,
        total,
        settlements: [],
      };
      this.settlementDetailsDialog = true;

      // Query the individual settlements
      this.$apollo
        .query({
          query: gql`
            query individualSettlementsInPeriodByAttorneyByMonth(
              $startDate: String!
              $endDate: String!
            ) {
              individualSettlementsInPeriodByAttorneyByMonth(
                startDate: $startDate
                endDate: $endDate
              ) {
                caseNumber
                clientName
                settlementDate
                attorney
              }
            }
          `,
          variables: {
            startDate: this.createMonthStartDate(monthStart),
            endDate: this.createMonthEndDate(monthStart),
          },
          fetchPolicy: "network-only",
        })
        .then(({ data }) => {
          if (data && data.individualSettlementsInPeriodByAttorneyByMonth) {
            // Get the list of attorneys to include in this query
            const attorneysToInclude = this.getAttorneysToInclude(attorney);

            // Filter the results by the list of attorneys and exclude CS
            const allSettlements =
              data.individualSettlementsInPeriodByAttorneyByMonth;
            const filteredSettlements = allSettlements.filter(
              (settlement) =>
                attorneysToInclude.includes(settlement.attorney) &&
                settlement.attorney !== "CS" &&
                settlement.attorney !== "WDM"
            );

            this.selectedSettlementDetails.settlements = filteredSettlements;
            this.selectedSettlementDetails.loading = false;
          }
        })
        .catch((error) => {
          console.error("Error loading settlement details:", error);
          this.selectedSettlementDetails.loading = false;
          this.selectedSettlementDetails.error =
            "Failed to load settlement details";
        });
    },

    // Helper methods for settlement details dialog title
    getSettlementTitlePrefix() {
      if (!this.selectedSettlementDetails) return "";
      const { total } = this.selectedSettlementDetails;
      return `${total} Settlements by `;
    },

    getSettlementTitleSuffix() {
      if (!this.selectedSettlementDetails) return "";
      const { monthStart } = this.selectedSettlementDetails;

      if (monthStart) {
        // Format the month for display
        const [year, month] = monthStart.split("-").map(Number);
        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        const formattedMonth = `${monthNames[month - 1]} ${year}`;
        return ` in ${formattedMonth}`;
      } else {
        return "";
      }
    },

    // Export settlements to CSV
    exportSettlementsToCSV() {
      if (
        !this.selectedSettlementDetails ||
        !this.selectedSettlementDetails.settlements ||
        this.selectedSettlementDetails.settlements.length === 0
      ) {
        return;
      }

      const settlements = this.selectedSettlementDetails.settlements;
      const fileName = `attorney_settlements_${
        this.selectedSettlementDetails.attorney
      }_${new Date().toISOString().slice(0, 10)}.csv`;

      // Define the fields to include in the CSV
      const fields = [
        { key: "caseNumber", label: "Case Number" },
        { key: "clientName", label: "Client Name" },
        {
          key: "settlementDate",
          label: "Settlement Date",
          formatter: this.formatDate,
        },
        { key: "attorney", label: "Attorney" },
      ];

      // Create CSV header row
      let csvContent =
        fields.map((field) => `"${field.label}"`).join(",") + "\n";

      // Add data rows
      settlements.forEach((settlement) => {
        const row = fields.map((field) => {
          // Get the value, apply formatter if specified
          let value = field.formatter
            ? field.formatter(settlement[field.key])
            : settlement[field.key];
          // Escape quotes and wrap in quotes
          return `"${String(value || "").replace(/"/g, '""')}"`;
        });
        csvContent += row.join(",") + "\n";
      });

      // Create a download link and trigger it
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");

      // Create a URL for the blob
      const url = URL.createObjectURL(blob);

      // Set link properties
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";

      // Add to document, click it, then remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    // Project the end-of-month value based on current progress
    projectMonthEndValue(currentValue) {
      if (!currentValue || this.currentDayOfMonth === 0) return 0;

      const numValue =
        typeof currentValue === "string"
          ? parseFloat(currentValue)
          : currentValue;
      const daysElapsed = this.currentDayOfMonth;
      const totalDays = this.daysInCurrentMonth;

      // Calculate projected value using simple linear projection
      return (numValue / daysElapsed) * totalDays;
    },

    // Get a tooltip message for projected values
    getProjectedTooltip(currentValue, isFeesTable = false) {
      if (!currentValue) return "";

      const numValue =
        typeof currentValue === "string"
          ? parseFloat(currentValue)
          : currentValue;
      const projected = this.projectMonthEndValue(numValue);

      if (isFeesTable) {
        return `Current: ${this.formatCurrency(
          numValue
        )}, Projected: ${this.formatCurrency(projected)}`;
      } else {
        return `Current: ${numValue}, Projected: ${Math.round(projected)}`;
      }
    },
  },
  mounted() {
    // Initialize the component safely
    try {
      // Set a timeout to ensure the query only runs after component is fully initialized
      setTimeout(() => {
        this.refetchQueries();
      }, 100);
    } catch (error) {
      console.error("Error during component mount:", error);
    }
  },
  errorCaptured(err, vm, info) {
    // Log any errors that occur in child components or this component
    console.error("Error captured in OrgStaffAttorneyStats:", err, info);
    return false; // Don't propagate the error
  },
};
</script>

<style scoped>
/* Adjust spacing or widths as needed */
.mb-5 {
  margin-bottom: 20px;
}

/* Conditional formatting styles for tables */
/* Fees Table: Green ≥ $180k, Yellow $150k-180k, Red < $150k */
::v-deep .attorney-fees-table td.high-value {
  background-color: #e8f5e9 !important; /* light green */
  color: #2e7d32 !important; /* darker green */
  font-weight: 500;
}

::v-deep .attorney-fees-table td.medium-value {
  background-color: #fff8e1 !important; /* light yellow */
  color: #f57f17 !important; /* amber/orange */
  font-weight: 500;
}

::v-deep .attorney-fees-table td.low-value {
  background-color: #ffebee !important; /* light red */
  color: #c62828 !important; /* darker red */
  font-weight: 500;
}

/* Demands Table: Green ≥ 18, Yellow 15-18, Red < 15 */
::v-deep .attorney-demands-table td.high-value-demand {
  background-color: #e8f5e9 !important; /* light green */
  color: #2e7d32 !important; /* darker green */
  font-weight: 500;
}

::v-deep .attorney-demands-table td.medium-value-demand {
  background-color: #fff8e1 !important; /* light yellow */
  color: #f57f17 !important; /* amber/orange */
  font-weight: 500;
}

::v-deep .attorney-demands-table td.low-value-demand {
  background-color: #ffebee !important; /* light red */
  color: #c62828 !important; /* darker red */
  font-weight: 500;
}

/* Clickable cell styling */
.clickable-cell {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  font-weight: 500;
  border-bottom: 1px dashed;
  padding-bottom: 1px;
}

.clickable-cell:hover {
  opacity: 0.8;
  border-bottom: 1px solid;
}

/* Monthly average display */
.monthly-average {
  font-size: 0.85em;
  color: rgba(0, 0, 0, 0.6);
  white-space: nowrap;
}

/* Customize table cells */
::v-deep .attorney-fees-table th,
::v-deep .attorney-fees-table td {
  padding: 4px 8px !important;
  min-width: 80px;
  text-align: left;
  font-size: 0.9rem;
  height: auto !important;
  line-height: 1.2;
}

::v-deep .attorney-fees-table th:first-child,
::v-deep .attorney-fees-table td:first-child {
  text-align: left;
  min-width: 120px;
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: white;
}

::v-deep .attorney-fees-table th:nth-child(2),
::v-deep .attorney-fees-table td:nth-child(2) {
  text-align: left;
  min-width: 180px;
  position: sticky;
  left: 120px;
  z-index: 2;
  background-color: white;
}

/* Allow text to wrap in detail table cells */
::v-deep .v-data-table td {
  white-space: normal;
  max-width: 300px;
}

/* Condensed table styles */
::v-deep .v-data-table {
  line-height: 1;
}

::v-deep .v-data-table > .v-data-table__wrapper > table {
  border-spacing: 0;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  height: auto !important;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  height: auto !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  font-size: 0.85rem;
}

/* Fee details dialog table styling - more spacious than the main table */
::v-deep .fee-details-table tbody tr td {
  padding: 4px 8px !important;
  line-height: 1.4 !important;
  height: 48px !important;
  vertical-align: middle;
}

::v-deep .fee-details-table thead tr th {
  padding: 10px 8px !important;
  font-weight: 500;
  height: 48px !important;
  vertical-align: middle;
}

/* Add some spacing to the fee details container */
::v-deep .fee-details-table {
  margin-top: 10px;
}

/* Fee amount styling */
.fee-amount {
  font-weight: 500;
  color: inherit;
  text-decoration: none;
  border-bottom: 1px dashed;
  padding-bottom: 1px;
}

.fee-amount:hover {
  opacity: 0.8;
  border-bottom: 1px solid;
}

/* Customize attorney demands table cells */
::v-deep .attorney-demands-table th,
::v-deep .attorney-demands-table td {
  padding: 4px 8px !important;
  min-width: 80px;
  text-align: left;
  font-size: 0.9rem;
  height: auto !important;
  line-height: 1.2;
}

::v-deep .attorney-demands-table th:first-child,
::v-deep .attorney-demands-table td:first-child {
  text-align: left;
  min-width: 120px;
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: white;
}

::v-deep .attorney-demands-table th:nth-child(2),
::v-deep .attorney-demands-table td:nth-child(2) {
  text-align: left;
  min-width: 120px;
  position: sticky;
  left: 120px;
  z-index: 2;
  background-color: white;
}

/* Make sure the demand details table uses the same styling as the fee details table */
::v-deep .demand-details-table tbody tr td {
  padding: 4px 8px !important;
  line-height: 1.4 !important;
  height: 48px !important;
  vertical-align: middle;
}

::v-deep .demand-details-table thead tr th {
  padding: 10px 8px !important;
  font-weight: 500;
  height: 48px !important;
  vertical-align: middle;
}

/* Add some spacing to the demand details container */
::v-deep .demand-details-table {
  margin-top: 10px;
}

/* Settlements Table: Green ≥ 5, Yellow 3-5, Red < 3 */
::v-deep .attorney-settlement-table td.high-value-settlement {
  background-color: #e8f5e9 !important; /* light green */
  color: #2e7d32 !important; /* darker green */
  font-weight: 500;
}

::v-deep .attorney-settlement-table td.medium-value-settlement {
  background-color: #fff8e1 !important; /* light yellow */
  color: #f57f17 !important; /* amber/orange */
  font-weight: 500;
}

::v-deep .attorney-settlement-table td.low-value-settlement {
  background-color: #ffebee !important; /* light red */
  color: #c62828 !important; /* darker red */
  font-weight: 500;
}

/* Customize attorney settlement table cells */
::v-deep .attorney-settlement-table th,
::v-deep .attorney-settlement-table td {
  padding: 4px 8px !important;
  min-width: 80px;
  text-align: left;
  font-size: 0.9rem;
  height: auto !important;
  line-height: 1.2;
}

::v-deep .attorney-settlement-table th:first-child,
::v-deep .attorney-settlement-table td:first-child {
  text-align: left;
  min-width: 120px;
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: white;
}

::v-deep .attorney-settlement-table th:nth-child(2),
::v-deep .attorney-settlement-table td:nth-child(2) {
  text-align: left;
  min-width: 120px;
  position: sticky;
  left: 120px;
  z-index: 2;
  background-color: white;
}

/* Make sure the settlement details table uses the same styling as the fee details table */
::v-deep .settlement-details-table tbody tr td {
  padding: 4px 8px !important;
  line-height: 1.4 !important;
  height: 48px !important;
  vertical-align: middle;
}

::v-deep .settlement-details-table thead tr th {
  padding: 10px 8px !important;
  font-weight: 500;
  height: 48px !important;
  vertical-align: middle;
}

/* Add some spacing to the settlement details container */
::v-deep .settlement-details-table {
  margin-top: 10px;
}

/* Current month projection styles */
::v-deep .attorney-fees-table td.projected-high-value,
::v-deep .attorney-demands-table td.projected-high-value,
::v-deep .attorney-settlement-table td.projected-high-value {
  position: relative;
}

::v-deep .attorney-fees-table td.projected-high-value::after,
::v-deep .attorney-demands-table td.projected-high-value::after,
::v-deep .attorney-settlement-table td.projected-high-value::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 12px 12px 0;
  border-style: solid;
  border-color: #2e7d32 transparent;
}

::v-deep .attorney-fees-table td.projected-medium-value,
::v-deep .attorney-demands-table td.projected-medium-value,
::v-deep .attorney-settlement-table td.projected-medium-value {
  position: relative;
}

::v-deep .attorney-fees-table td.projected-medium-value::after,
::v-deep .attorney-demands-table td.projected-medium-value::after,
::v-deep .attorney-settlement-table td.projected-medium-value::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 12px 12px 0;
  border-style: solid;
  border-color: #f57f17 transparent;
}

::v-deep .attorney-fees-table td.projected-low-value,
::v-deep .attorney-demands-table td.projected-low-value,
::v-deep .attorney-settlement-table td.projected-low-value {
  position: relative;
}

::v-deep .attorney-fees-table td.projected-low-value::after,
::v-deep .attorney-demands-table td.projected-low-value::after,
::v-deep .attorney-settlement-table td.projected-low-value::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 12px 12px 0;
  border-style: solid;
  border-color: #c62828 transparent;
}
</style>
