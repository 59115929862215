<template>
  <div>
    <highcharts ref="chart" :options="chartOptions" />
  </div>
</template>

<script>
import accounting from "accounting";
export default {
  props: {
    options: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  mounted() {
    setTimeout(() => {
      if (!this.$refs.chart) return;
      this.$refs.chart.chart.reflow();
    }, 200);
  },
  computed: {
    chartOptions() {
      return Object.assign({}, this.options, {
        credits: {
          enabled: false,
        },
        chart: {
          type: "column",
          height: 220,
          animation: true,
          marginTop: 10,
          marginRight: 30,
          spacing: [10, 10, 15, 10], // top, right, bottom, left
        },
        title: {
          text: "",
        },
        legend: {
          enabled: false,
        },
        yAxis: [
          {
            min: 0,
            title: {
              text: "Fees Amount",
            },
            labels: {
              formatter: function() {
                return accounting.formatMoney(this.value, "$", 0);
              },
            },
            gridLineWidth: 0.5,
          },
        ],
        tooltip: {
          useHTML: true,
          style: {
            padding: "0",
            pointerEvents: "none",
          },
          backgroundColor: "rgba(255, 255, 255, 0.95)",
          borderWidth: 1,
          borderColor: "rgba(0, 0, 0, 0.1)",
          borderRadius: 5,
          shadow: true,
          formatter: function() {
            const name = this.point.name;
            const value = this.y;
            const fullName = this.point.fullName || name;

            // Basic tooltip without complex HTML
            let html = `<div class="attorney-tooltip">`;

            // Add name and code - always display fullName first
            html += `<div class="tooltip-name">${fullName}</div>`;

            // Show staff code as secondary information if different from full name
            if (name !== fullName) {
              html += `<div class="tooltip-code">${name}</div>`;
            }

            // Add team if available
            if (this.point.team) {
              html += `<div class="tooltip-team">${this.point.team}</div>`;
            }

            // Add amount
            html += `<div class="tooltip-amount">${accounting.formatMoney(
              value
            )}</div>`;

            // Add percentage of total
            if (this.series && this.series.data) {
              const total = this.series.data.reduce(
                (sum, point) => sum + point.y,
                0
              );
              if (total > 0) {
                const percent = Math.round((value / total) * 100);
                html += `<div class="tooltip-percent">${percent}% of total fees</div>`;
              }
            }

            // Group members (if grouped view)
            if (this.point.groupMembers && this.point.groupMembers.length > 0) {
              html += `<div class="tooltip-group-members">`;
              html += `<div class="tooltip-group-members-title">Includes:</div>`;

              // Sort by amount
              const sortedMembers = [...this.point.groupMembers]
                .sort((a, b) => b.amount - a.amount)
                .slice(0, 5); // Limit to top 5 to keep tooltip manageable

              sortedMembers.forEach((member) => {
                const displayName = member.fullName || member.name;
                html += `<div class="tooltip-group-member">`;
                html += `<span>${displayName}</span>: `;
                html += `<span>${accounting.formatMoney(member.amount)}</span>`;
                html += `</div>`;
              });

              if (this.point.groupMembers.length > 5) {
                html += `<div class="tooltip-more">and ${this.point.groupMembers
                  .length - 5} more...</div>`;
              }

              html += `</div>`;
            }

            html += `</div>`;
            return html;
          },
        },
        plotOptions: {
          column: {
            borderRadius: 4,
            borderWidth: 0,
            pointPadding: 0.2,
            groupPadding: 0.1,
            dataLabels: {
              enabled: true,
              crop: false,
              overflow: "none",
              formatter: function() {
                if (this.y < 1000) {
                  return "";
                }
                return accounting.formatMoney(this.y);
              },
              style: {
                fontSize: "11px",
                fontWeight: "normal",
                textOutline: "1px contrast",
              },
            },
            states: {
              hover: {
                brightness: 0.1,
                borderWidth: 1,
              },
            },
          },
        },
      });
    },
  },
};
</script>

<style scoped>
:deep(.attorney-tooltip) {
  padding: 8px;
  min-width: 180px;
  z-index: 1000;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
  line-height: 1.3;
}

:deep(.tooltip-name) {
  font-size: 14px;
  font-weight: bold;
  overflow-wrap: break-word;
  margin-bottom: 4px;
}

:deep(.tooltip-code) {
  font-size: 11px;
  color: #666;
  margin-bottom: 4px;
}

:deep(.tooltip-team) {
  font-size: 12px;
  color: #555;
  margin-bottom: 8px;
}

:deep(.tooltip-amount) {
  font-size: 16px;
  font-weight: bold;
  color: #2196f3;
  margin-bottom: 6px;
}

:deep(.tooltip-percent) {
  font-size: 11px;
  margin-bottom: 8px;
  color: #666;
}

:deep(.tooltip-group-members) {
  margin-top: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 5px;
}

:deep(.tooltip-group-members-title) {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

:deep(.tooltip-group-member) {
  font-size: 11px;
  margin-bottom: 2px;
  color: #333;
}

:deep(.tooltip-more) {
  font-size: 11px;
  font-style: italic;
  color: #666;
  margin-top: 3px;
}
</style>
