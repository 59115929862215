<template>
  <div>
    <v-row>
      <v-col sm="12" md="8">
        <v-card>
          <v-progress-linear
            color="primary"
            indeterminate
            v-if="$apollo.loading"
          ></v-progress-linear>
          <v-toolbar flat dense>
            <span class="text-subtitle-1">Pre-lit Fees in Period</span>
            <v-spacer></v-spacer>
            <v-btn-toggle
              v-model="preLitSortOption"
              mandatory
              dense
              color="primary"
              class="elevation-0"
            >
              <v-btn value="name" small>
                <v-icon small>mdi-sort-alphabetical-ascending</v-icon>
                <span class="caption ml-1 d-none d-sm-inline">Name</span>
              </v-btn>
              <v-btn value="amount" small>
                <v-icon small>mdi-sort-numeric-descending</v-icon>
                <span class="caption ml-1 d-none d-sm-inline">Amount</span>
              </v-btn>
            </v-btn-toggle>
            <v-divider vertical class="mx-2"></v-divider>
            <v-btn-toggle
              v-model="showGroupedView"
              mandatory
              dense
              color="primary"
              class="elevation-0 ml-2"
            >
              <v-btn :value="true" small>
                <v-icon small>mdi-view-grid</v-icon>
                <span class="caption ml-1 d-none d-sm-inline">Grouped</span>
              </v-btn>
              <v-btn :value="false" small>
                <v-icon small>mdi-view-list</v-icon>
                <span class="caption ml-1 d-none d-sm-inline">Detailed</span>
              </v-btn>
            </v-btn-toggle>
          </v-toolbar>
          <v-card-text v-if="organization" class="pt-0 pb-2">
            <div class="d-flex flex-wrap fee-summary">
              <div class="mr-2 fee-stat">
                <div class="overline">Total Fees</div>
                <div class="body-1 font-weight-bold">
                  {{ formatMoney(preLitFeesSummary.total) }}
                </div>
              </div>
              <div class="mr-2 fee-stat">
                <div class="overline">Top Performer</div>
                <div class="body-1">{{ preLitFeesSummary.topTeam }}</div>
                <div class="caption">
                  {{ formatMoney(preLitFeesSummary.topAmount) }}
                </div>
              </div>
            </div>
          </v-card-text>
          <div class="chart-vis">
            <org-fees-in-period-by-attorney
              :options="feesInPeriodByPreLitAttorney"
            />
          </div>
        </v-card>
      </v-col>
      <v-col sm="12" md="4">
        <v-card>
          <v-progress-linear
            color="primary"
            indeterminate
            v-if="$apollo.loading"
          ></v-progress-linear>
          <v-toolbar flat dense>
            <span class="text-subtitle-1">Lit Fees in Period</span>
            <v-spacer></v-spacer>
            <v-btn-toggle
              v-model="litSortOption"
              mandatory
              dense
              color="primary"
              class="elevation-0"
            >
              <v-btn value="name" small>
                <v-icon small>mdi-sort-alphabetical-ascending</v-icon>
              </v-btn>
              <v-btn value="amount" small>
                <v-icon small>mdi-sort-numeric-descending</v-icon>
              </v-btn>
            </v-btn-toggle>
            <v-divider vertical class="mx-2"></v-divider>
            <v-btn-toggle
              v-model="showGroupedView"
              mandatory
              dense
              color="primary"
              class="elevation-0 ml-2"
            >
              <v-btn :value="true" small>
                <v-icon small>mdi-view-grid</v-icon>
              </v-btn>
              <v-btn :value="false" small>
                <v-icon small>mdi-view-list</v-icon>
              </v-btn>
            </v-btn-toggle>
          </v-toolbar>

          <v-card-text v-if="organization" class="pt-0 pb-2">
            <div class="d-flex flex-wrap fee-summary">
              <div class="mr-2 fee-stat">
                <div class="overline">Total Fees</div>
                <div class="body-1 font-weight-bold">
                  {{ formatMoney(litFeesSummary.total) }}
                </div>
              </div>
              <div class="mr-2 fee-stat">
                <div class="overline">Top Performer</div>
                <div class="body-1">{{ litFeesSummary.topTeam }}</div>
                <div class="caption">
                  {{ formatMoney(litFeesSummary.topAmount) }}
                </div>
              </div>
            </div>
          </v-card-text>

          <div class="chart-vis">
            <org-fees-in-period-by-attorney
              :options="feesInPeriodByLitAttorney"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <fees-by-week></fees-by-week>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import gql from "graphql-tag";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import sumBy from "lodash/sumBy";
import accounting from "accounting";
import { mapGetters } from "vuex";
import OrgFeesInPeriodByAttorney from "../charts/OrgFeesInPeriodByAttorney";
import FeesByWeek from "../charts/FeesByWeek.vue";
import { getPreLitAttorneysDb, getLitAttorneysDb } from "../util";

export default {
  components: {
    OrgFeesInPeriodByAttorney,
    FeesByWeek,
  },
  data() {
    return {
      preLitSortOption: "amount",
      litSortOption: "amount",
      showGroupedView: true,
      // Mapping for fee source groups
      sourceMappings: {
        ACS: ["TRS", "RAS", "ACS"], // ACS group includes TRS, RAS
        CLP: ["PPR", "CLP"], // CLP group includes PPR
        RCS: ["WC", "SSD", "PL", "RCS"], // RCS group includes WC, SSD, PL
        LS: ["KNP", "EMR", "LS"], // LS group includes KNP, EMR
      },
    };
  },
  apollo: {
    organization: {
      query: gql`
        query OrgHomeFeeCharts(
          $id: ID!
          $startDate: String!
          $endDate: String!
        ) {
          organization(id: $id) {
            id
            name
            departments {
              id
              name
            }
            users {
              id
              staffCode
              firstName
              lastName
              departments {
                id
                name
              }
            }
            saFeesInPeriodByAttorney(startDate: $startDate, endDate: $endDate) {
              attorney
              amount
            }
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id,
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
      skip() {
        return this.startDate === null || this.endDate === null;
      },
    },
  },
  computed: {
    ...mapGetters(["currentUser", "startDate", "endDate"]),

    // Map of staff codes to user and department info
    userDepartmentMap() {
      if (!this.organization || !this.organization.users) return {};

      const staffMap = {};
      const departmentsMap = {};

      // Create a map of departments for quick lookup
      if (this.organization.departments) {
        this.organization.departments.forEach((dept) => {
          departmentsMap[dept.id] = dept.name;
        });
      }

      // Process users directly from organization
      this.organization.users.forEach((user) => {
        if (user.staffCode) {
          // Get primary department info (if exists)
          let departmentName = null;
          let departmentId = null;

          if (user.departments && user.departments.length > 0) {
            departmentId = user.departments[0].id;
            departmentName = user.departments[0].name;
          }

          staffMap[user.staffCode] = {
            departmentName,
            departmentId,
            userId: user.id,
            fullName: `${user.firstName} ${user.lastName}`.trim(),
          };
        }
      });

      return staffMap;
    },

    // Process PreLit Attorney Data
    processedPreLitData() {
      if (!this.organization) return [];

      // Default hard-coded list of known pre-litigation attorneys
      const defaultPreLitAttorneys = [
        "BLA",
        "CAG",
        "CKS",
        "DB",
        "DJO",
        "GWG",
        "JTA",
        "KBL",
        "LP",
        "PHC",
        "RCS",
        "RJE",
        "RJL",
      ];

      // Try to get attorneys from the database
      let preLitAttorneysFromDb = [];
      if (this.organization.departments) {
        try {
          // Get pre-lit attorneys from the database using departments
          preLitAttorneysFromDb = getPreLitAttorneysDb({
            departments: this.organization.departments,
          });
        } catch (error) {
          // Fallback to direct extraction if utility function fails
          preLitAttorneysFromDb = [];
        }
      }

      // Also extract attorneys from the users in case the DB function fails
      const preLitAttorneysFromUsers = [];
      if (this.organization.users) {
        this.organization.users.forEach((user) => {
          if (user.staffCode && user.departments) {
            // Check if user belongs to the pre-litigation department
            const isPreLitAttorney = user.departments.some(
              (dept) => dept.name === "Pre-Litigation Attorneys"
            );

            if (isPreLitAttorney) {
              preLitAttorneysFromUsers.push(user.staffCode);
            }
          }
        });
      }

      // Use DB attorneys first, then users, then default
      const preLitAttorneys =
        preLitAttorneysFromDb.length > 0
          ? preLitAttorneysFromDb
          : preLitAttorneysFromUsers.length > 0
          ? preLitAttorneysFromUsers
          : defaultPreLitAttorneys;

      // Process fee data to standardize attorney codes
      let allFeeData = this.organization.saFeesInPeriodByAttorney
        .map((d) => {
          if (d.attorney === "RLG-P") {
            return { attorney: "RG", amount: d.amount };
          } else if (d.attorney) {
            return {
              attorney: d.attorney.replace(/-P/i, ""),
              amount: d.amount,
            };
          } else {
            return { attorney: "", amount: 0 };
          }
        })
        .filter((d) => d.attorney); // Remove entries with empty attorney codes

      // Known litigation attorneys that we want to exclude from pre-lit
      const litAttorneys = ["ECD", "MJK", "RG", "SF"];

      // Find all unique attorney codes from fees that aren't lit attorneys
      const attorneysWithFees = allFeeData
        .filter((d) => !litAttorneys.includes(d.attorney))
        .map((d) => d.attorney);

      // Combine pre-lit attorneys and those with fees while removing duplicates
      const allAttorneyCodes = [
        ...new Set([...preLitAttorneys, ...attorneysWithFees]),
      ];

      // Group the fee data by attorney for easy lookup
      const groupedByAttorney = groupBy(allFeeData, (d) => d.attorney);

      // Create a series entry for each attorney
      let series = allAttorneyCodes.map((code) => {
        const staffInfo = this.userDepartmentMap[code] || {};
        const fullName = staffInfo.fullName || code;

        // Get fee amount if it exists, otherwise 0
        const feeEntries = groupedByAttorney[code] || [];
        const amount = feeEntries.length ? parseFloat(feeEntries[0].amount) : 0;

        return {
          name: code,
          y: amount,
          team: staffInfo.departmentName || null,
          departmentId: staffInfo.departmentId || null,
          fullName: fullName,
        };
      });

      // Do NOT filter out zero fee attorneys - show all for comparison
      return series;
    },

    // Summary statistics for pre-lit fees
    preLitFeesSummary() {
      // Always calculate totals from the raw data, not the grouped data
      const data = this.processedPreLitData;
      if (!data.length) return { total: 0 };

      const total = sumBy(data, "y");
      const average = total / data.length;

      // Use potentially grouped data for top performers display
      const viewData = this.groupFeeData(data);

      // Only include non-zero fee earners in top/bottom calculations
      const activeFeeEarners = viewData.filter((d) => d.y > 0);
      if (!activeFeeEarners.length) {
        return {
          total,
          average,
          topTeam: "N/A",
          bottomTeam: "N/A",
          topAmount: 0,
          bottomAmount: 0,
        };
      }

      const sortedByAmount = orderBy(activeFeeEarners, ["y"], ["desc"]);
      const topTeam = sortedByAmount[0].fullName;
      const topAmount = sortedByAmount[0].y;

      const bottomTeam = sortedByAmount[sortedByAmount.length - 1].fullName;
      const bottomAmount = sortedByAmount[sortedByAmount.length - 1].y;

      return { total, average, topTeam, bottomTeam, topAmount, bottomAmount };
    },

    feesInPeriodByPreLitAttorney() {
      if (!this.organization) return {};

      // Sort the data based on the selected option
      const sortOption = this.preLitSortOption;
      let sortField = "name";
      let sortDirection = "asc";

      if (sortOption === "amount") {
        sortField = "y";
        sortDirection = "desc";
      }

      // Get raw data and apply grouping if needed
      const processedData = this.groupFeeData(this.processedPreLitData);

      // Sort the data to avoid Highcharts error #15
      const sortedSeries = orderBy(processedData, [sortField], [sortDirection]);

      // Add colors based on performance
      const totalFees = sumBy(sortedSeries, "y");
      const activeFeeEarners = sortedSeries.filter((s) => s.y > 0);
      const averageFees =
        activeFeeEarners.length > 0 ? totalFees / activeFeeEarners.length : 1;

      const coloredSeries = sortedSeries.map((item) => {
        // Different color for top performers
        if (item.y > averageFees * 1.2) {
          return { ...item, color: "#4CAF50" }; // Green for high performers
        } else if (item.y < averageFees * 0.8 && item.y > 0) {
          return { ...item, color: "#FF5252" }; // Red for low performers
        }
        return item;
      });

      // Ensure we have properly formatted data for the chart
      return {
        xAxis: {
          type: "category",
          categories: coloredSeries.map((item) => item.name),
        },
        series: [
          {
            name: "Attorneys",
            data: coloredSeries.map((item) => ({
              name: item.name,
              y: item.y,
              color: item.color,
              fullName: item.fullName,
              team: item.team,
              departmentId: item.departmentId,
              groupMembers: item.groupMembers,
            })),
          },
        ],
      };
    },

    // Process Lit Attorney Data
    processedLitData() {
      if (!this.organization) return [];

      // Default hard-coded list of known litigation attorneys
      const defaultLitAttorneys = ["ECD", "MJK", "RG", "SF"];

      // Try to get attorneys from the database
      let litAttorneysFromDb = [];
      if (this.organization.departments) {
        try {
          // Get lit attorneys from the database using departments
          litAttorneysFromDb = getLitAttorneysDb({
            departments: this.organization.departments,
          });
        } catch (error) {
          // Fallback to direct extraction if utility function fails
          litAttorneysFromDb = [];
        }
      }

      // Also extract attorneys from the users in case the DB function fails
      const litAttorneysFromUsers = [];
      if (this.organization.users) {
        this.organization.users.forEach((user) => {
          if (user.staffCode && user.departments) {
            // Check if user belongs to the litigation department
            const isLitAttorney = user.departments.some(
              (dept) => dept.name === "Litigation Attorneys"
            );

            if (isLitAttorney) {
              litAttorneysFromUsers.push(user.staffCode);
            }
          }
        });
      }

      // Use DB attorneys first, then users, then default
      const litAttorneys =
        litAttorneysFromDb.length > 0
          ? litAttorneysFromDb
          : litAttorneysFromUsers.length > 0
          ? litAttorneysFromUsers
          : defaultLitAttorneys;

      // Process fee data to standardize attorney codes
      let allFeeData = this.organization.saFeesInPeriodByAttorney
        .map((d) => {
          if (d.attorney === "RLG-P") {
            return { attorney: "RG", amount: d.amount };
          } else if (d.attorney) {
            return {
              attorney: d.attorney.replace(/-P/i, ""),
              amount: d.amount,
            };
          } else {
            return { attorney: "", amount: 0 };
          }
        })
        .filter((d) => d.attorney); // Remove entries with empty attorney codes

      // Group the fee data by attorney for easy lookup
      const groupedByAttorney = groupBy(allFeeData, (d) => d.attorney);

      // Create a series entry for each litigation attorney
      let series = litAttorneys.map((code) => {
        const staffInfo = this.userDepartmentMap[code] || {};
        const fullName = staffInfo.fullName || code;

        // Get fee amount if it exists, otherwise 0
        const feeEntries = groupedByAttorney[code] || [];
        const amount = feeEntries.length ? parseFloat(feeEntries[0].amount) : 0;

        return {
          name: code,
          y: amount,
          team: staffInfo.departmentName || null,
          departmentId: staffInfo.departmentId || null,
          fullName: fullName,
        };
      });

      // Do NOT filter out zero fee attorneys - show all for comparison
      return series;
    },

    // Summary statistics for lit fees
    litFeesSummary() {
      // Always calculate totals from the raw data, not the grouped data
      const data = this.processedLitData;
      if (!data.length) return { total: 0 };

      const total = sumBy(data, "y");

      // Use potentially grouped data for top performers display
      const viewData = this.groupFeeData(data);

      // Only include non-zero fee earners in top calculations
      const activeFeeEarners = viewData.filter((d) => d.y > 0);
      if (!activeFeeEarners.length) {
        return { total, topTeam: "N/A", topAmount: 0 };
      }

      const sortedByAmount = orderBy(activeFeeEarners, ["y"], ["desc"]);
      const topTeam = sortedByAmount[0].fullName;
      const topAmount = sortedByAmount[0].y;

      return { total, topTeam, topAmount };
    },

    feesInPeriodByLitAttorney() {
      if (!this.organization) return {};

      // Sort the data based on the selected option
      const sortOption = this.litSortOption;
      let sortField = "name";
      let sortDirection = "asc";

      if (sortOption === "amount") {
        sortField = "y";
        sortDirection = "desc";
      }

      // Get raw data and apply grouping if needed
      const processedData = this.groupFeeData(this.processedLitData);

      // Sort the data to avoid Highcharts error #15
      const sortedSeries = orderBy(processedData, [sortField], [sortDirection]);

      // Add colors to highlight performers
      const totalFees = sumBy(sortedSeries, "y");
      const activeFeeEarners = sortedSeries.filter((s) => s.y > 0);
      const averageFees =
        activeFeeEarners.length > 0 ? totalFees / activeFeeEarners.length : 1;

      const coloredSeries = sortedSeries.map((item) => {
        if (item.y > averageFees * 1.2) {
          return { ...item, color: "#4CAF50" }; // Green for high performers
        } else if (item.y < averageFees * 0.8 && item.y > 0) {
          return { ...item, color: "#FF5252" }; // Red for low performers
        }
        return item;
      });

      // Ensure we have properly formatted data for the chart
      return {
        xAxis: {
          type: "category",
          categories: coloredSeries.map((item) => item.name),
        },
        series: [
          {
            name: "Attorneys",
            data: coloredSeries.map((item) => ({
              name: item.name,
              y: item.y,
              color: item.color,
              fullName: item.fullName,
              team: item.team,
              departmentId: item.departmentId,
              groupMembers: item.groupMembers,
            })),
          },
        ],
      };
    },
  },
  methods: {
    formatMoney(value) {
      return accounting.formatMoney(value);
    },

    // Return the group key for a given attorney code
    getGroupForSource(sourceCode) {
      for (const [groupKey, sources] of Object.entries(this.sourceMappings)) {
        if (sources.includes(sourceCode)) {
          return groupKey;
        }
      }
      // If not found in any group, return the original code
      return sourceCode;
    },

    // Group fee data by source groups
    groupFeeData(data) {
      if (!this.showGroupedView) {
        return data; // Return ungrouped data for detailed view
      }

      // Create a map to hold grouped data
      const groupedData = new Map();

      data.forEach((item) => {
        const groupKey = this.getGroupForSource(item.name);

        if (!groupedData.has(groupKey)) {
          // Find a representative full name for the group
          // Use the first user in the group with a matching name pattern
          let groupDisplayName = groupKey;

          // Look up full name from userDepartmentMap if available
          if (this.userDepartmentMap[groupKey]) {
            groupDisplayName = this.userDepartmentMap[groupKey].fullName;
          } else {
            // Check if any users have a staffCode that starts with this group key
            Object.entries(this.userDepartmentMap).forEach(
              ([staffCode, userData]) => {
                if (
                  (staffCode === groupKey ||
                    staffCode.startsWith(groupKey + "-")) &&
                  userData.fullName &&
                  groupDisplayName === groupKey
                ) {
                  groupDisplayName = userData.fullName;
                }
              }
            );
          }

          // Create a new entry with same properties but zeroed amount
          groupedData.set(groupKey, {
            name: groupKey,
            y: 0,
            team: item.team,
            departmentId: item.departmentId,
            fullName: groupDisplayName, // Use the representative full name
            groupMembers: [], // Track which sources are in this group
          });
        }

        const group = groupedData.get(groupKey);
        group.y += item.y; // Add amount to the group

        // Only add to group members if it has a value
        if (item.y > 0) {
          group.groupMembers.push({
            name: item.name,
            fullName: item.fullName,
            amount: item.y,
          });
        }
      });

      return Array.from(groupedData.values());
    },
  },
};
</script>

<style scoped lang="scss">
.chart-vis {
  margin-top: 8px;
  margin-bottom: 24px;
  padding-bottom: 16px;
}

.v-row + .v-row {
  margin-top: 24px;
}

.fee-stat {
  margin-bottom: 8px;
  margin-top: 8px;
  min-width: 120px;
}

.fee-summary {
  padding-top: 4px;
  padding-bottom: 4px;
  margin-bottom: 4px;
}

.v-card {
  margin-bottom: 16px;
}
</style>
