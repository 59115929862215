<template>
  <v-card>
    <v-toolbar flat dense>
      <span class="text-subtitle-1">Fees By Week</span>
      <v-spacer></v-spacer>

      <!-- <div class="autocomplete">
        <v-autocomplete
          class="pt-5"
          :items="officeOptions"
          v-model="selectedOffice"
        ></v-autocomplete>
      </div> -->
    </v-toolbar>

    <v-card-text>
      <div class="d-flex flex-wrap fee-summary">
        <v-card outlined class="ma-1 summary-card">
          <v-card-subtitle class="py-1">Current Week</v-card-subtitle>
          <v-card-text class="py-1">
            <div class="text-h6">{{ formatMoney(currentWeekFees) }}</div>
            <div class="progress-indicator">
              <span class="percentage">{{ currentWeekPercentage }}%</span>
              <v-icon small :color="currentWeekTrend.color">{{
                currentWeekTrend.icon
              }}</v-icon>
            </div>
            <div
              class="mt-1 projection-indicator"
              :class="{ 'success--text': isOnTrack, 'error--text': !isOnTrack }"
            >
              <v-icon x-small :color="isOnTrack ? 'success' : 'error'">
                {{ isOnTrack ? "mdi-check-circle" : "mdi-alert-circle" }}
              </v-icon>
              <span class="ml-1 caption">{{ projectionMessage }}</span>
            </div>
          </v-card-text>
        </v-card>

        <v-card outlined class="ma-1 summary-card">
          <v-card-subtitle class="py-1">Weekly Goal</v-card-subtitle>
          <v-card-text class="py-1">
            <div class="text-h6">{{ formatMoney(weeklyGoal) }}</div>
          </v-card-text>
        </v-card>

        <v-card outlined class="ma-1 summary-card">
          <v-card-subtitle class="py-1">Total YTD</v-card-subtitle>
          <v-card-text class="py-1">
            <div class="text-h6">{{ formatMoney(totalFees) }}</div>
          </v-card-text>
        </v-card>

        <v-card outlined class="ma-1 summary-card">
          <v-card-subtitle class="py-1">YTD Goal</v-card-subtitle>
          <v-card-text class="py-1">
            <div class="text-h6">{{ formatMoney(ytdGoal) }}</div>
            <div class="progress-indicator">
              <span class="percentage">{{ ytdPercentage }}%</span>
              <v-icon small :color="ytdTrend.color">{{ ytdTrend.icon }}</v-icon>
            </div>
          </v-card-text>
        </v-card>

        <v-card outlined class="ma-1 summary-card">
          <v-card-subtitle class="py-1">4-Week Trend</v-card-subtitle>
          <v-card-text class="py-1">
            <div class="d-flex align-center">
              <v-icon medium :color="recentTrend.color">{{
                recentTrend.icon
              }}</v-icon>
              <span class="ml-2">{{ recentTrend.text }}</span>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-card-text>

    <highcharts ref="feesByWeek" :options="chartOptions"></highcharts>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import filter from "lodash/filter";
import accounting from "accounting";
import { getFirstDayOfWeekFromWeekNumber } from "../util";

export default {
  data() {
    return {
      officeOptions: [
        { text: "All Markets", value: null },
        {
          text: "(2 - WA Main) Spokane Valley Office",
          value: "(2 - WA Main) Spokane Valley Office",
        },
        {
          text: "(1 - UT Main) Sandy Office",
          value: "(1 - UT Main) Sandy Office",
        },
        {
          text: "(3 - ID Main) Meridian Office",
          value: "(3 - ID Main) Meridian Office",
        },
        { text: "Sea-Tac", value: "Sea-Tac" },
        { text: "Other", value: "Other" },
        { text: "Wyoming", value: "Wyoming" },
        { text: "Montana", value: "Montana" },
        { text: "Colorado", value: "Colorado" },
        { text: "Oregon", value: "Oregon" },
      ],
      selectedOffice: null,
      weeklyGoal: 540000,
    };
  },
  mounted() {
    setTimeout(() => {
      if (!this.$refs.feesByWeek) return;
      this.$refs.feesByWeek.chart.reflow();
    }, 200);
  },
  computed: {
    // ...mapGetters([]),
    feesForChartSeries() {
      if (!this.feesByWeek) return [];

      let fees = this.feesByWeek;
      let goal = this.weeklyGoal;
      if (this.selectedOffice) {
        fees = filter(
          this.feesByWeekByOffice,
          (f) => f.office === this.selectedOffice
        );
        switch (this.selectedOffice) {
          case "(2 - WA Main) Spokane Valley Office":
            goal = 134400;
            break;
          case "(1 - UT Main) Sandy Office":
            goal = 258000;
            break;
          case "(3 - ID Main) Meridian Office":
            goal = 91728;
            break;
          case "Sea-Tac":
            goal = 0;
            break;
          case "Other":
            goal = 0;
            break;
          case "Wyoming":
            goal = 0;
            break;
          case "Montana":
            goal = 0;
            break;
          case "Colorado":
            goal = 0;
            break;
          case "Oregon":
            goal = 0;
            break;
          default:
            goal = 0;
            break;
        }
      }

      let weeklyFees = {
        name: "Weekly Fees",
        data: fees.map((f) => {
          return parseFloat(f.fees, 10);
        }),
        color: "#4CAF50",
        type: "column",
        colorByPoint: true,
        zones: [
          {
            value: this.weeklyGoal,
            color: "#FF5252", // Red for below goal
          },
          {
            color: "#4CAF50", // Green for at or above goal
          },
        ],
      };
      let array = fees,
        aggregateSum;
      let aggregateFees = {
        name: "Aggregate Fees",
        data: array.map(
          (f) => (aggregateSum = (aggregateSum || 0) + parseFloat(f.fees, 10))
        ),
        color: "#2196F3",
        type: "line",
        yAxis: 1,
      };
      let goalSum;
      let goalFees = {
        name: "Goal Fees",
        data: fees.map(() => (goalSum = (goalSum || 0) + goal)),
        color: "#FF5252",
        dashStyle: "shortdash",
        type: "line",
        yAxis: 1,
      };
      return [weeklyFees, aggregateFees, goalFees];
    },

    currentWeekFees() {
      if (!this.feesByWeek || this.feesByWeek.length === 0) return 0;
      return parseFloat(this.feesByWeek[this.feesByWeek.length - 1].fees, 10);
    },

    // Get the current day of the week (0-6, where 0 is Sunday)
    currentDayOfWeek() {
      return new Date().getDay() || 7; // Convert Sunday from 0 to 7
    },

    // Calculate the expected fee amount by current day of week
    expectedFeesByDay() {
      // Assuming 5 business days (Monday-Friday) to achieve the goal
      // We calculate what percentage of the weekly goal should be achieved by now
      const businessDays = 5;
      const today = this.currentDayOfWeek;

      // If weekend (Saturday or Sunday), use Friday's target
      const daysIn = today >= 6 ? 5 : Math.min(today, 5);

      return (this.weeklyGoal / businessDays) * daysIn;
    },

    // Determine if on track to meet weekly goal
    isOnTrack() {
      return this.currentWeekFees >= this.expectedFeesByDay;
    },

    // Message about current projection
    projectionMessage() {
      if (this.currentWeekFees >= this.weeklyGoal) {
        return "Goal achieved!";
      }

      if (this.isOnTrack) {
        return "On track to meet goal";
      } else {
        // Calculate how much more is needed daily to reach goal
        const daysLeft = Math.max(5 - this.currentDayOfWeek, 0);
        if (daysLeft === 0) {
          return "Goal not achieved";
        }

        const amountNeeded = this.weeklyGoal - this.currentWeekFees;
        const dailyNeeded = amountNeeded / daysLeft;

        return `Needs ${accounting.formatMoney(dailyNeeded)}/day to reach goal`;
      }
    },

    currentWeekPercentage() {
      return Math.round((this.currentWeekFees / this.weeklyGoal) * 100);
    },

    currentWeekTrend() {
      if (this.feesByWeek && this.feesByWeek.length > 1) {
        const currentWeek = this.currentWeekFees;
        const prevWeek = parseFloat(
          this.feesByWeek[this.feesByWeek.length - 2].fees,
          10
        );
        if (currentWeek > prevWeek) {
          return { icon: "mdi-arrow-up", color: "success" };
        } else if (currentWeek < prevWeek) {
          return { icon: "mdi-arrow-down", color: "error" };
        }
      }
      return { icon: "mdi-minus", color: "grey" };
    },

    totalFees() {
      if (!this.feesByWeek) return 0;
      return this.feesByWeek.reduce(
        (sum, item) => sum + parseFloat(item.fees, 10),
        0
      );
    },

    ytdGoal() {
      if (!this.feesByWeek) return 0;
      return this.weeklyGoal * this.feesByWeek.length;
    },

    ytdPercentage() {
      return Math.round((this.totalFees / this.ytdGoal) * 100);
    },

    ytdTrend() {
      const percentage = this.ytdPercentage;
      if (percentage >= 100) {
        return { icon: "mdi-check-circle", color: "success" };
      } else if (percentage >= 90) {
        return { icon: "mdi-thumb-up", color: "info" };
      } else if (percentage >= 75) {
        return { icon: "mdi-equal", color: "warning" };
      } else {
        return { icon: "mdi-alert", color: "error" };
      }
    },

    recentTrend() {
      if (!this.feesByWeek || this.feesByWeek.length < 4) {
        return {
          icon: "mdi-chart-line",
          color: "grey",
          text: "Insufficient data",
        };
      }

      // Get the last 4 completed weeks
      const recentCompletedWeeks = this.feesByWeek.slice(-5, -1);
      const increases = [];

      for (let i = 1; i < recentCompletedWeeks.length; i++) {
        const current = parseFloat(recentCompletedWeeks[i].fees, 10);
        const previous = parseFloat(recentCompletedWeeks[i - 1].fees, 10);
        increases.push(current > previous);
      }

      // Add the current week's projection to the trend if it's not the weekend
      if (this.currentDayOfWeek < 6) {
        const lastCompletedWeek = parseFloat(
          recentCompletedWeeks[recentCompletedWeeks.length - 1].fees,
          10
        );

        // Extrapolate current week's projected final value based on day of week
        const projectedCurrentWeek =
          this.currentDayOfWeek > 0
            ? this.currentWeekFees * (5 / this.currentDayOfWeek)
            : this.currentWeekFees;

        increases.push(projectedCurrentWeek > lastCompletedWeek);
      }

      const trueCount = increases.filter(Boolean).length;
      const totalWeeks = increases.length;

      if (trueCount >= Math.ceil(totalWeeks * 0.6)) {
        return {
          icon: "mdi-trending-up",
          color: "success",
          text: "Upward trend",
        };
      } else if (trueCount <= Math.floor(totalWeeks * 0.4)) {
        return {
          icon: "mdi-trending-down",
          color: "error",
          text: "Downward trend",
        };
      } else {
        return {
          icon: "mdi-trending-neutral",
          color: "warning",
          text: "Stable trend",
        };
      }
    },

    chartOptions() {
      return {
        credits: {
          enabled: false,
        },
        title: {
          text: "",
        },
        yAxis: [
          {
            title: {
              text: "Weekly Fees",
            },
            labels: {
              formatter: function() {
                return accounting.formatMoney(this.value, "$", 0);
              },
            },
          },
          {
            title: {
              text: "Aggregate Fees",
            },
            opposite: true,
            labels: {
              formatter: function() {
                return accounting.formatMoney(this.value, "$", 0);
              },
            },
          },
        ],
        xAxis: {
          title: {
            text: "Week Number",
          },
          labels: {
            formatter: function() {
              return `Week ${this.value}`;
            },
          },
        },
        tooltip: {
          formatter: function() {
            let year = new Date().getFullYear();
            const startDate = getFirstDayOfWeekFromWeekNumber(year, this.x);

            let tooltip = `<b>Week ${this.x} - ${startDate}</b><br/>`;
            tooltip += `${this.series.name}: ${accounting.formatMoney(
              this.y
            )}<br/>`;

            if (this.series.name === "Weekly Fees") {
              const goal = 540000;
              const percentage = Math.round((this.y / goal) * 100);
              const variance = this.y - goal;
              const status =
                this.y >= goal
                  ? '<span style="color:#4CAF50">✓ Goal Achieved</span>'
                  : '<span style="color:#FF5252">✗ Below Goal</span>';

              tooltip += `Goal: ${accounting.formatMoney(goal)}<br/>`;
              tooltip += `Performance: ${percentage}%<br/>`;
              tooltip += `Variance: ${accounting.formatMoney(variance)}<br/>`;
              tooltip += status;
            }

            return tooltip;
          },
        },
        plotOptions: {
          series: {
            pointStart: 1,
          },
          line: {
            marker: {
              enabled: true,
              radius: 4,
            },
          },
          column: {
            borderRadius: 3,
            pointPadding: 0.2,
            groupPadding: 0.1,
          },
        },
        series: this.feesForChartSeries,
      };
    },
  },
  methods: {
    formatMoney(value) {
      return accounting.formatMoney(value);
    },
  },
  apollo: {
    feesByWeek: {
      query: gql`
        query OrgHomeFeeChartsFeesByWeek {
          feesByWeek {
            week
            year
            fees
          }
        }
      `,
    },
    feesByWeekByOffice: {
      query: gql`
        query OrgHomeFeeChartsFeesByWeekByOffice {
          feesByWeekByOffice {
            week
            year
            fees
            office
          }
        }
      `,
    },
  },
};
</script>

<style scoped>
.fee-summary {
  margin-bottom: 16px;
}

.summary-card {
  flex: 1;
  min-width: 180px;
}

.progress-indicator {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
}

.projection-indicator {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
}

.percentage {
  margin-right: 4px;
}

@media screen and (max-width: 767px) {
  .summary-card {
    min-width: 140px;
  }
}
</style>
